import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { login, mailerResetPassword, resetPasswordToken } from "../apiRoutes";
import { useApi } from "../useApi";
import { UserContext } from "../UserContext";
import {
  ActionIcon,
  Button,
  Image,
  PasswordInput,
  TextInput,
} from "@mantine/core";
import { IconArrowLeft, IconCheck, IconX } from "@tabler/icons-react";
import { showNotification } from "@mantine/notifications";
import { MailjetTemplate } from "../dto/templates";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginPage = ({ onLogin }: { onLogin: () => void }) => {
  const [currentPasswordWrong, setCurrentPasswordWrong] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const api = useApi();
  const { setUser } = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const query = useQuery();
  const token = query.get("token");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [newPasswordNotMatching, setNewPasswordNotMatching] = useState(false);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = await login(api, username, password);
      if (user === "not_activated") {
        showNotification({
          message: "Votre compte n'est pas activé",
          icon: <IconX size="1.1rem" />,
          color: "red",
        });
        return;
      }
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      onLogin(); // Update the isLoggedIn state in the App component
      navigate("/");
    } catch (error: any) {
      console.error("Failed to login", error);
      if (error.response?.status === 401) {
        setCurrentPasswordWrong(true);
      }
      // Handle login error
    }
  };

  const handleReset = async () => {
    await mailerResetPassword(api, {
      username,
      template: MailjetTemplate.RESET_PASSWORD,
    });

    showNotification({
      title: "Succès",
      message: "Si un compte est associé à ce login, un mail a été envoyé",
      icon: <IconCheck size="1.1rem" />,
      color: "teal",
    });
    setResetPassword(false);
    setCurrentPasswordWrong(false);
  };

  const handleNewPassword = async () => {
    if (newPassword1 !== newPassword2) {
      setNewPasswordNotMatching(true);
      return;
    }
    setNewPasswordNotMatching(false);
    await resetPasswordToken(api, {
      token: token ?? "",
      newPassword: newPassword1,
    });
    navigate("/login");
    showNotification({
      title: "Succès",
      message: "Veuilez vous connecter avec votre nouveau mot de passe",
      icon: <IconCheck size="1.1rem" />,
      color: "teal",
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <div
        style={{
          flexBasis: "100%",
          maxWidth: "360px",
          margin: "auto",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <div>
          <Image
            src="/LOGOhorizontal-transparent_noir.png"
            alt="Logo"
            width={142}
          />
          {resetPassword ? (
            <>
              <ActionIcon mt={32} onClick={() => setResetPassword(false)}>
                <IconArrowLeft />
              </ActionIcon>
              <TextInput
                mt={20}
                label="Login"
                placeholder="Entrez votre login"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Button
                style={{ flex: 1, marginTop: 24, width: "100%" }}
                color="default"
                type="submit"
                onClick={handleReset}
              >
                Envoyer un mail de réinitialisation
              </Button>
              <Button
                style={{ flex: 1, marginTop: 24, width: "100%" }}
                variant="default"
                type="submit"
                onClick={() => setResetPassword(false)}
              >
                Annuler
              </Button>
            </>
          ) : token ? (
            <>
              <h1 style={{ marginBottom: 12, marginTop: 70 }}>
                Réinitialiser votre mot de passe
              </h1>
              <PasswordInput
                mt={20}
                label="Nouveau mot de passe"
                placeholder="Entrez le nouveau mot de passe"
                value={newPassword1}
                onChange={(e) => setNewPassword1(e.target.value)}
                error={
                  newPasswordNotMatching
                    ? "Les mots de passe ne correspondent pas"
                    : null
                }
                description="Doit comporter 8 caractères dont une lettre et un chiffre"
                required
              />
              <PasswordInput
                mt={20}
                label="Confirmation du mot de passe"
                placeholder="Entrez le nouveau mot de passe"
                value={newPassword2}
                onChange={(e) => setNewPassword2(e.target.value)}
                error={
                  newPasswordNotMatching
                    ? "Les mots de passe ne correspondent pas"
                    : null
                }
                required
              />
              <Button
                style={{ flex: 1, marginTop: 24, width: "100%" }}
                color="default"
                type="submit"
                onClick={handleNewPassword}
                disabled={
                  newPassword1.length < 8 ||
                  !/\d/.test(newPassword1) ||
                  !/[a-zA-Z]/.test(newPassword1)
                }
              >
                Envoyer
              </Button>
            </>
          ) : (
            <>
              <h1 style={{ marginBottom: 12, marginTop: 70 }}>Connexion</h1>
              <p style={{ margin: 0 }}>
                Entrez vos identifiants afin de vous connecter
              </p>
              <TextInput
                mt={32}
                label="Login"
                placeholder="Entrez votre login"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <PasswordInput
                mt={20}
                label="Mot de passe"
                placeholder="Entrez votre mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={
                  currentPasswordWrong
                    ? "Mot de passe ou login incorrect"
                    : null
                }
              />
              <p
                style={{
                  margin: 0,
                  marginTop: 24,
                  color: "#2C3F53",
                  cursor: "pointer",
                }}
                onClick={() => setResetPassword(true)}
              >
                Mot de passe oublié
              </p>
              <Button
                style={{ flex: 1, marginTop: 24, width: "100%" }}
                color="primary"
                type="submit"
                onClick={handleSubmit}
              >
                Connexion
              </Button>
            </>
          )}
        </div>
      </div>
      <div
        style={{
          flexBasis: "100%",
          minWidth: "360px",
          maxWidth: "55%",
          backgroundImage: `url("Section.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          display: windowWidth >= 800 ? "block" : "none",
        }}
      />
    </div>
  );
};

export default LoginPage;
