import CustomModal from "./CustomModal";
import { Button, PasswordInput, Title } from "@mantine/core";
import { useState } from "react";
import { useApi } from "../../useApi";
import { postNewPassword } from "../../apiRoutes";
import { IconCheck } from "@tabler/icons-react";
import { showNotification } from "@mantine/notifications";

const NewPasswordModal = ({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const api = useApi();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPasswordWrong, setCurrentPasswordWrong] = useState(false);
  const [newPasswordNotMatching, setNewPasswordNotMatching] = useState(false);

  const handleNewPassword = async () => {
    setCurrentPasswordWrong(false);
    setNewPasswordNotMatching(false);
    if (newPassword1 !== newPassword2) {
      setNewPasswordNotMatching(true);
      return;
    }
    setLoading(true);
    try {
      const ret = await postNewPassword(api, currentPassword, newPassword1);
      console.log(1, ret);
      if (ret.status === "success") {
        setOpened(false);
        setNewPasswordNotMatching(false);
        setCurrentPasswordWrong(false);
        setCurrentPassword("");
        setNewPassword1("");
        setNewPassword2("");
        showNotification({
          title: "Succès",
          message: "Mot de passe modifié",
          icon: <IconCheck size="1.1rem" />,
          color: "teal",
        });
      } else if (ret.status === "wrong_password") {
        setCurrentPasswordWrong(true);
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        setCurrentPasswordWrong(true);
      }
      console.log(11, e);
    }
    setLoading(false);
  };

  return (
    <CustomModal opened={opened} setOpened={setOpened} loading={loading}>
      <Title
        order={3}
        mt={10}
        mb={10}
        style={{ textAlign: "center", fontSize: 20 }}
        color="#333333"
      >
        Modifier mon mot de passe
      </Title>
      <PasswordInput
        mt={20}
        label="Mot de passe actuel"
        placeholder="Entrez le mot de passe"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        error={currentPasswordWrong ? "Mot de passe incorrect" : null}
        required
      />
      <PasswordInput
        mt={20}
        label="Nouveau mot de passe"
        placeholder="Entrez le nouveau mot de passe"
        value={newPassword1}
        onChange={(e) => setNewPassword1(e.target.value)}
        error={
          newPasswordNotMatching
            ? "Les mots de passe ne correspondent pas"
            : null
        }
        description="Doit comporter 8 caractères dont une lettre et un chiffre"
        required
      />
      <PasswordInput
        mt={20}
        label="Confirmation du mot de passe"
        placeholder="Entrez le nouveau mot de passe"
        value={newPassword2}
        onChange={(e) => setNewPassword2(e.target.value)}
        error={
          newPasswordNotMatching
            ? "Les mots de passe ne correspondent pas"
            : null
        }
        required
      />
      <div style={{ marginTop: "2em", display: "flex" }}>
        <Button
          style={{ marginRight: "1em", flex: 1, color: "#333333" }}
          onClick={() => setOpened(false)}
          variant="default"
        >
          Annuler
        </Button>
        <Button
          style={{ flex: 1 }}
          color="primary"
          onClick={handleNewPassword}
          disabled={
            newPassword1.length < 8 ||
            !/\d/.test(newPassword1) ||
            !/[a-zA-Z]/.test(newPassword1)
          }
        >
          Enregistrer
        </Button>
      </div>
    </CustomModal>
  );
};
export default NewPasswordModal;
