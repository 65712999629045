import { Button, LoadingOverlay, Paper, Text } from "@mantine/core";
import { IconDownload, IconMoveSelected, IconTrashRed } from "./IconType";
import { FileInfoDto, State } from "../dto/googledrive";
import { Table } from "@tanstack/react-table";
import DeleteModal from "./secondary/DeleteModal";
import { useEffect, useState } from "react";
import { useApi } from "../useApi";
import {
  deleteFile,
  downloadFiles,
  mailer,
  moveFile,
  validateFile,
} from "../apiRoutes";
import MoveModal from "./secondary/MoveModal";
import { MailjetTemplate } from "../dto/templates";

const ActionFooter = ({
  table,
  refresh,
}: {
  table: Table<FileInfoDto>;
  refresh: () => Promise<void>;
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingDeleteModal, setLoadingDeleteModal] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [openMoveModal, setOpenMoveModal] = useState(false);
  const [loadingMoveModal, setLoadingMoveModal] = useState(false);
  const [directDelete, setDirectDelete] = useState("");
  const api = useApi();
  const selectedRows = table.getSelectedRowModel();
  const totalSelected = selectedRows.rows.length;

  const handleDownload = async () => {
    console.log("handleDownload");
    if (totalSelected === 0) return;
    setLoadingDownload(true);
    const filesToDownload = selectedRows.rows.map(
      (row) => row.original.id || ""
    );
    await downloadFiles(api, filesToDownload);
    setLoadingDownload(false);
  };

  const handleDelete = async () => {
    if (totalSelected === 0 && directDelete.length === 0) return;
    try {
      setLoadingDeleteModal(true);
      const deletePromises =
        directDelete.length === 0
          ? selectedRows.rows.map((row) => {
              if (!row?.original?.id) return null;
              return deleteFile(api, row.original.id);
            })
          : [deleteFile(api, directDelete)];

      await Promise.all(deletePromises);
      setTimeout(async () => {
        await refresh();
        setDirectDelete("");
        setOpenDeleteModal(false);
        setLoadingDeleteModal(false);
        table.resetRowSelection(true);
      }, 500);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMove = async (destination: string) => {
    if (totalSelected === 0 || !destination) return;
    try {
      setLoadingMoveModal(true);
      const movePromises = selectedRows.rows.map((row) => {
        if (!row?.original?.id) return null;
        return moveFile(api, row.original.id, destination);
      });

      await Promise.all(movePromises);
      await refresh();
      setOpenMoveModal(false);
      setLoadingMoveModal(false);
      table.resetRowSelection(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const handleDeleteFiles = (e: any) => {
      const { fileId } = e.detail;
      setDirectDelete(fileId);
      setOpenDeleteModal(true);
    };

    const handleValidateFiles = async (e: any) => {
      const { fileId, realState } = e.detail;
      const userDestination = window.location.pathname.split("/")[2];
      await validateFile(api, fileId, realState);
      await refresh();
      if (realState === State.drop) {
        await mailer(api, {
          username: userDestination,
          template: MailjetTemplate.FILE_IN_PRODUCTION,
          variables: {
            name: userDestination,
          },
        });
      }
    };

    window.addEventListener("deleteFiles", handleDeleteFiles);
    window.addEventListener("validateFiles", handleValidateFiles);

    // Make sure to clean up the event listener on unmount
    return () => {
      window.removeEventListener("deleteFiles", handleDeleteFiles);
      window.removeEventListener("validateFiles", handleValidateFiles);
    };
  }, [refresh, api]);

  return (
    <>
      <DeleteModal
        handleDelete={handleDelete}
        opened={openDeleteModal}
        setOpened={setOpenDeleteModal}
        loading={loadingDeleteModal}
      />
      {totalSelected !== 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            bottom: 20,
            left: 0,
            right: 0,
            pointerEvents: "none", // This makes the div unclickable
          }}
        >
          {openMoveModal && (
            <MoveModal
              handleMove={handleMove}
              opened={openMoveModal}
              setOpened={setOpenMoveModal}
              loading={loadingMoveModal}
              selectedRows={selectedRows}
            />
          )}
          <Paper
            shadow="xl"
            style={{
              display: "flex",
              padding: 20,
              gap: 12,
              boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.15)",
              alignItems: "center",
              pointerEvents: "auto",
            }}
          >
            <Text
              style={{
                fontWeight: 700,
                fontSize: 16,
                color: "#333333",
              }}
              mr={10}
            >
              {totalSelected} élément{totalSelected === 1 ? "" : "s"}{" "}
              sélectionné
              {totalSelected === 1 ? "" : "s"}
            </Text>
            <Text
              style={{
                fontWeight: 400,
                fontSize: 14,
                textDecorationLine: "underline",
                color: "#475467",
                cursor: "pointer",
              }}
              mr={10}
              onClick={() => table.resetRowSelection(true)}
            >
              Tout déselectionner
            </Text>
            <Button
              leftIcon={IconMoveSelected}
              onClick={() => {
                setOpenMoveModal(true);
                setLoadingMoveModal(false);
              }}
              styles={{
                inner: { paddingLeft: 4 },
              }}
              variant="default"
            >
              Déplacer
            </Button>
            <Button
              leftIcon={IconTrashRed}
              onClick={() => {
                if (selectedRows.rows.length === 0) return;
                setLoadingDeleteModal(false);
                setOpenDeleteModal(true);
                setDirectDelete("");
              }}
              styles={{
                inner: { paddingLeft: 4 },
                label: { color: "#E74C3C" },
              }}
              variant="default"
            >
              Supprimer
            </Button>
            <Button
              leftIcon={IconDownload}
              onClick={handleDownload}
              styles={{
                inner: { paddingLeft: 4 },
              }}
              disabled={loadingDownload}
            >
              Télécharger
              <LoadingOverlay
                visible={loadingDownload}
                style={{ borderRadius: 7 }}
              />
            </Button>
          </Paper>
        </div>
      )}
    </>
  );
};
export default ActionFooter;
