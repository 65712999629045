import React, { useContext } from "react";
import { HeaderAction } from "../components/HeaderAction";
import UserForm from "../components/UserForm";
import { UserContext } from "../UserContext";

const NewUser = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <HeaderAction planning={user?.isAdminPlanning} />
      <div style={{ padding: 32, backgroundColor: "#FCFCFD" }}>
        <h1
          style={{
            margin: 0,
            marginTop: -10,
            fontSize: 40,
            fontWeight: 500,
          }}
        >
          Ajouter un utilisateur
        </h1>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #EAECF0",
            marginTop: 20,
            marginBottom: 32,
          }}
        />
        <div style={{ display: "flex", gap: 10 }}>
          <p
            style={{ fontSize: 18, fontWeight: 600, maxWidth: 280, margin: 0 }}
          >
            Créez les identifiants de ce nouvel utilisateur
          </p>
          <UserForm />
        </div>
      </div>
    </>
  );
};

export default NewUser;
