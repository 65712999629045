import React, { useState, useRef } from "react";
import { HeaderAction } from "../components/HeaderAction";
import { ActionIcon, Box, Flex, Title } from "@mantine/core";
import { TaskDto } from "../dto/user";
import { useApi } from "../useApi";
import { getTasksMember } from "../apiRoutes";
import moment, { Moment } from "moment";
import Legend from "../components/calendar/Legend";
import DayCell from "../components/DayCell";
import { capitalizeFirstLetter, generateDays } from "../utils";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import DayCellTitle from "../components/DayCellTitle";

const MemberCalendar = () => {
  const [refresh, setRefresh] = useState<number>(0);
  const [tasks, setTasks] = useState<TaskDto[]>([]);
  const [days, setDays] = useState<Moment[]>([]);
  const [, setLoading] = useState<boolean>(true);
  const api = useApi();
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const [currentMonth, setCurrentMonth] = useState(moment());
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const dateRefs = useRef<{ [key: string]: HTMLDivElement }>({});

  React.useEffect(() => {
    setDays(generateDays(currentMonth));
  }, [currentMonth]);

  React.useEffect(() => {
    const fetchTasks = async () => {
      const { data }: { data: TaskDto[] } = await getTasksMember(api);
      console.log("tasks", data);
      setLoading(false);
      setTasks(data);
    };
    fetchTasks();
  }, [api, refresh]);

  // @ts-ignore
  const filterTasksForDay = (day) => {
    const dayStr = day.format("YYYY-MM-DD");
    return tasks.filter((task) => {
      // @ts-ignore
      const startDateStr = task?.startDate?.split("T")[0];
      // @ts-ignore
      const endDateStr = task?.endDate?.split("T")[0] || startDateStr;
      return startDateStr <= dayStr && dayStr <= endDateStr;
    });
  };

  React.useEffect(() => {
    const currentDayKey = moment(today).format("YYYY-MM-DD");
    if (dateRefs.current[currentDayKey]) {
      const element = dateRefs.current[currentDayKey];
      const offsetLeft = element.offsetLeft;
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTo({
          left: offsetLeft - 34,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <Box bg={"#F9FAFB"} pb={1}>
      <HeaderAction planning />
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #EAECF0",
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
        }}
        mr={16}
        ml={16}
        mt={16}
        p={24}
      >
        <Flex gap={10} mb={4}>
          <ActionIcon
            variant="default"
            onClick={() =>
              setCurrentMonth(currentMonth.clone().subtract(1, "months"))
            }
          >
            <IconChevronLeft size="1rem" />
          </ActionIcon>
          <Title mb={5} mt={2}>
            Planning {capitalizeFirstLetter(currentMonth.format("MMMM YYYY"))}
          </Title>
          <ActionIcon
            variant="default"
            onClick={() =>
              setCurrentMonth(currentMonth.clone().add(1, "months"))
            }
          >
            <IconChevronRight size="1rem" />
          </ActionIcon>
        </Flex>
        <Legend />
      </Box>
      <div
        ref={scrollContainerRef}
        style={{
          display: "flex",
          margin: "0px 16px 16px 16px",
          backgroundColor: "white",
          borderLeft: "1px solid #EAECF0",
          overflowX: "auto",
        }}
      >
        {days.map((day) => {
          const dayStr = day.format("YYYY-MM-DD");
          const tasksForDay = filterTasksForDay(day);
          return (
            <div
              key={dayStr}
              ref={(el) => {
                if (el) dateRefs.current[dayStr] = el;
              }}
            >
              <DayCellTitle
                day={day}
                formattedToday={formattedToday}
                isMemberCalendar
              />
              <DayCell
                day={day}
                tasks={tasksForDay}
                isMembers
                setRefresh={setRefresh}
              />
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default MemberCalendar;
