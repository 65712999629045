import React, { useContext, useEffect, useState } from "react";
import { HeaderAction } from "../components/HeaderAction";
import { UserContext } from "../UserContext";
import { useApi } from "../useApi";
import { getFolderDetails, getGoogleDriveHome } from "../apiRoutes";
import GoogleDriveTable from "../components/GoogleDriveTable";
import { FileInfoDto } from "../dto/googledrive";
import { useNavigate, useParams } from "react-router-dom";
import {
  IconChevronRight,
  IconHome,
  IconWarningBlack,
} from "../components/IconType";
import HelpModal from "../components/secondary/HelpModal";
import { UserDto } from "../dto/user";

const HomePage = ({
  profile = false,
  userProfile = undefined,
}: {
  profile?: boolean;
  userProfile?: UserDto;
}) => {
  const { user } = useContext(UserContext);
  const api = useApi();
  const [tableContent, setTableContent] = useState<FileInfoDto[]>([]);
  const [folderName, setFolderName] = useState<string>("");
  const [openHelpModal, setOpenHelpModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const { folderId } = useParams<{ folderId?: string }>();
  const navigate = useNavigate();

  const refresh = async (full = false) => {
    if (!user || !api || (profile && !userProfile)) {
      return;
    }
    if (full) {
      setTableContent([]);
      setFolderName("");
      setLoading(true);
    }
    if (folderId || profile) {
      const [fileList, folderDetails] = await Promise.all([
        getGoogleDriveHome(api, folderId ? folderId : userProfile?.folderId),
        getFolderDetails(api, folderId ? folderId : userProfile?.folderId),
      ]);
      console.log("fileList folder", fileList);
      setTableContent(fileList);
      setFolderName(folderDetails?.name);
    } else {
      const fileList: FileInfoDto[] = await getGoogleDriveHome(api);
      console.log("fileList root", fileList);
      setTableContent(fileList);
      setFolderName("");
    }
    setTimeout(() => setLoading(false), 200);
  };

  useEffect(() => {
    refresh(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, api, folderId, userProfile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [folderId]);

  if (profile)
    return (
      <GoogleDriveTable
        tableContent={tableContent}
        folderName={folderName}
        refresh={refresh}
        loading={loading}
      />
    );

  return (
    <>
      <HeaderAction />
      <HelpModal opened={openHelpModal} setOpened={setOpenHelpModal} />
      <div style={{ padding: 32, backgroundColor: "#FCFCFD" }}>
        {folderId ? (
          <div
            style={{
              gap: 15,
              display: "flex",
              verticalAlign: "",
              marginBottom: 15,
            }}
          >
            <div onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
              {IconHome}
            </div>
            <div>{IconChevronRight}</div>
            <div style={{ fontSize: 14, color: "#656565" }}>Fichiers</div>
            <div>{IconChevronRight}</div>
            <div style={{ fontSize: 14, color: "#656565" }}>{folderName}</div>
          </div>
        ) : null}
        {user?.role === "client" && (
          <div
            style={{
              border: "1px solid #333333",
              borderRadius: 12,
              marginBottom: 32,
              padding: 16,
              display: "flex",
              backgroundColor: "#F9FAFB",
              gap: 12,
              fontWeight: 700,
              font: "Helvetica Neue",
              fontSize: 14,
              cursor: "pointer",
            }}
            onClick={() => setOpenHelpModal(true)}
          >
            {IconWarningBlack}
            <div>
              <div>
                Avec votre enregistrement, n'oubliez pas SVP de nous envoyer :
              </div>
              <ul>
                <li>l'ordre du jour de la réunion</li>
                <li>la liste des participants à jour</li>
              </ul>
              <div style={{ marginTop: 12 }}> Besoin d’aide ?</div>
            </div>
          </div>
        )}
        <GoogleDriveTable
          tableContent={tableContent}
          folderName={folderName}
          refresh={refresh}
          loading={loading}
        />
      </div>
    </>
  );
};

export default HomePage;
