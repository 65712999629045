import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  useReactTable,
} from "@tanstack/react-table";
import { FileInfoDto } from "../dto/googledrive";
import React, { useContext } from "react";
import { useSkipper } from "./table/hooks";
import {
  columns,
  defaultColumn,
  fuzzyFilter,
  getTableMeta,
} from "./table/tableModels";
import CustomTable from "./table/CustomTable";
// import ActionButtons from "./table/ActionButtons";
import ActionFooter from "./ActionFooter";
import { UserContext } from "../UserContext";
import { Button, Input, Title } from "@mantine/core";
import NewFolder from "./NewFolder";
import { IconSearch } from "./IconType";
import DropFileButton from "./DropFileButton";
import { useParams } from "react-router-dom";

const GoogleDriveTable = ({
  tableContent,
  folderName,
  refresh,
  loading,
}: {
  tableContent: FileInfoDto[];
  folderName: string;
  refresh: () => Promise<void>;
  loading: boolean;
}) => {
  // const rerender = React.useReducer(() => ({}), {})[1];
  const { user } = useContext(UserContext);
  const { username } = useParams<{ username?: string }>();
  const [data, setData] = React.useState(tableContent);
  const refreshData = () => setData(tableContent);

  React.useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnPinning, setColumnPinning] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    autoResetPageIndex,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onGroupingChange: setGrouping,
    onColumnPinningChange: setColumnPinning,
    onRowSelectionChange: setRowSelection,
    meta: getTableMeta(setData, skipAutoResetPageIndex),
    state: {
      grouping,
      columnFilters,
      globalFilter,
      columnPinning,
      rowSelection,
    },
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    initialState: {
      pagination: {
        pageSize: 10000,
      },
    },
  });

  React.useEffect(() => {
    table.resetRowSelection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  // INITIAL SORTING
  // React.useEffect(() => {
  //   if (table.getState().columnFilters[0]?.id === "fullName") {
  //     if (table.getState().sorting[0]?.id !== "fullName") {
  //       table.setSorting([{ id: "fullName", desc: false }]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [table.getState().columnFilters[0]?.id]);

  return (
    <>
      <div
        style={{
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          border: "1px solid #EAECF0",
          padding: "20px 24px",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {user?.role === "client" ? (
            <div>
              {folderName?.length === 0 ? (
                <>
                  <Title pb={4}>Fichiers</Title>
                  Liste des fichiers de {user?.username}
                </>
              ) : (
                <>
                  <Title pb={4}>Dossier {folderName}</Title>
                  Liste des fichiers de {user?.username} dans le dossier{" "}
                  {folderName}
                </>
              )}
            </div>
          ) : (
            <div>
              {folderName?.length === 0 ? (
                <>
                  <Title pb={4}>Dossiers utilisateurs</Title>
                  Liste des dossiers utilisateurs
                </>
              ) : (
                <>
                  <Title pb={4}>Dossier {folderName}</Title>
                  Liste des fichiers de {username} dans le dossier {folderName}
                </>
              )}
            </div>
          )}
          <NewFolder refresh={refresh} user={user} />
        </div>
      </div>
      {(window.location.pathname !== "/" || user?.role === "client") && (
        <DropFileButton refresh={refresh} user={user} />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "12px 16px",
          borderRight: "1px solid #EAECF0",
          borderLeft: "1px solid #EAECF0",
        }}
      >
        <Button.Group>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "owner",
                  value: "",
                },
              ])
            }
          >
            Tous
          </Button>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "owner",
                  value: username ?? user?.username,
                },
              ])
            }
          >
            {username ?? user?.username}
          </Button>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "owner",
                  value: "Compte Rendu",
                },
              ])
            }
          >
            Compte Rendu
          </Button>
        </Button.Group>
        <Input
          placeholder="Rechercher"
          style={{ width: 300 }}
          styles={{ input: { height: 44 } }}
          icon={IconSearch}
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(event.currentTarget.value)}
        />
      </div>
      <div style={{ overflowX: "auto", display: "flex" }}>
        <CustomTable table={table} loading={loading} />
      </div>
      <ActionFooter table={table} refresh={refresh} />
      {/* <ActionButtons
        getSelectedRowModel={table.getSelectedRowModel}
        hasNextPage={table.getCanNextPage()}
        hasPreviousPage={table.getCanPreviousPage()}
        nextPage={table.nextPage}
        pageCount={table.getPageCount()}
        pageIndex={table.getState().pagination.pageIndex}
        pageSize={table.getState().pagination.pageSize}
        previousPage={table.previousPage}
        refreshData={refreshData}
        rerender={rerender}
        rowSelection={rowSelection}
        setPageIndex={table.setPageIndex}
        setPageSize={table.setPageSize}
        totalRows={table.getPrePaginationRowModel().rows.length}
      />
      <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </>
  );
};
export default GoogleDriveTable;
