import { Box, MultiSelect } from "@mantine/core";
import React, { KeyboardEvent, useState } from "react";

const MultipleDatesInput = ({
  multipleDates,
  setMultipleDates,
}: {
  multipleDates: string[];
  setMultipleDates: (dates: string[]) => void;
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const options = multipleDates.map((date, index) => ({
    value: date,
    label: date,
  }));

  const handleChange = (selectedValues: string[]) => {
    setMultipleDates(selectedValues);
  };

  const handleCreate = (query: string) => {
    const newOption = {
      value: query,
      label: query,
    };
    setMultipleDates([...multipleDates, query]);
    setSearchQuery("");
    return newOption;
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !multipleDates.includes(searchQuery)) {
      handleCreate(searchQuery);
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <Box mt={10}>
      <MultiSelect
        data={options}
        value={multipleDates}
        onChange={handleChange}
        placeholder="Ajouter les dates de la réunion (exemple: 3001)"
        creatable
        getCreateLabel={(query) => `+ Ajouter ${query}`}
        onCreate={handleCreate}
        label="Dates de la réunion"
        description="Appuyez sur Entrée pour ajouter une date"
        searchable
        onKeyDown={handleKeyDown}
        onSearchChange={handleSearchChange}
        searchValue={searchQuery}
      />
    </Box>
  );
};

export default MultipleDatesInput;
