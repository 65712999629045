import React from "react";
import GlowingIcon from "./secondary/GlowingIcon";
import UploadModal from "./UploadModal";
import { UserDto } from "../dto/user";

const DropFileButton = ({
  refresh,
  user,
}: {
  refresh: () => Promise<void>;
  user: UserDto | null;
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          backgroundColor: "white",
          padding: 16,
          borderRight: "1px solid #EAECF0",
          borderLeft: "1px solid #EAECF0",
          borderBottom: "1px solid #EAECF0",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <div
          style={{
            border: "1px solid #2E6DA4",
            width: "100%",
            height: 136,
            borderRadius: 12,
            backgroundColor: "#F5FBFF",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <GlowingIcon icon="upload" />
          <p style={{ margin: 0, fontWeight: 700, color: "#2C3F53" }}>
            Cliquez pour transmettre vos fichiers
          </p>
          <p style={{ margin: 0, color: "#656565" }}>
            MP3, MP4, MP4A, WMA, Word, PDF, JPG
          </p>
        </div>
      </div>
      <UploadModal
        opened={open}
        setOpened={setOpen}
        refresh={refresh}
        user={user}
      />
    </>
  );
};

export default DropFileButton;
