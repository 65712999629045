import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";
import { useSkipper } from "./table/hooks";
import {
  columnsUsers as columns,
  defaultColumn,
  fuzzyFilter,
  getTableMeta,
} from "./table/tableModels";
import CustomTable from "./table/CustomTable";
import { Button, Input, Title } from "@mantine/core";
import { IconSearch } from "./IconType";
import { UserDto } from "../dto/user";

const UsersTable = ({
  tableContent,
  refresh,
  loading,
}: {
  tableContent: UserDto[];
  refresh: () => Promise<void>;
  loading: boolean;
}) => {
  const [data, setData] = React.useState(tableContent);
  const refreshData = () => setData(tableContent);

  React.useEffect(() => {
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableContent]);

  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnPinning, setColumnPinning] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    {
      id: "activated",
      value: true,
    },
  ]);
  const [globalFilter, setGlobalFilter] = React.useState("");

  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    autoResetPageIndex,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onGroupingChange: setGrouping,
    onColumnPinningChange: setColumnPinning,
    onRowSelectionChange: setRowSelection,
    meta: getTableMeta(setData, skipAutoResetPageIndex),
    state: {
      grouping,
      columnFilters,
      globalFilter,
      columnPinning,
      rowSelection,
    },
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    initialState: {
      pagination: {
        pageSize: 10000,
      },
    },
  });

  React.useEffect(() => {
    table.resetRowSelection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <div
        style={{
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          border: "1px solid #EAECF0",
          padding: "20px 24px",
          backgroundColor: "white",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Title pb={4}>Utilisateurs</Title>
            Liste des utilisateurs de l’interface
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "12px 16px",
          borderRight: "1px solid #EAECF0",
          borderLeft: "1px solid #EAECF0",
        }}
      >
        <Button.Group>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "activated",
                  value: true,
                },
              ])
            }
          >
            Actifs
          </Button>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "role",
                  value: "",
                },
              ])
            }
          >
            Tous
          </Button>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "role",
                  value: "m",
                },
              ])
            }
          >
            Membres
          </Button>
          <Button
            variant="default"
            onClick={() =>
              setColumnFilters([
                {
                  id: "role",
                  value: "client",
                },
              ])
            }
          >
            Clients
          </Button>
        </Button.Group>
        <Input
          placeholder="Rechercher"
          style={{ width: 300 }}
          styles={{ input: { height: 44 } }}
          icon={IconSearch}
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(event.currentTarget.value)}
        />
      </div>
      <div style={{ overflowX: "auto", display: "flex" }}>
        <CustomTable table={table} loading={loading} />
      </div>
    </>
  );
};
export default UsersTable;
