import { MultiSelect } from "@mantine/core";
import { KeyboardEvent, useMemo, useState } from "react";
import { UserRole } from "../dto/user";

const DynamicMultiSelect = ({ form }: { form: any }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const options = useMemo(() => {
    return form.values.places.length > 0
      ? form.values.places.split("§").map((place: string) => ({
          value: place,
          label: place,
        }))
      : [];
  }, [form.values.places]);

  const handleChange = (selectedValues: string[]) => {
    const placesString = selectedValues.join("§");
    form.setFieldValue("places", placesString);
  };

  const handleCreate = (query: string): { value: string; label: string } => {
    const newOption = { value: query, label: query };
    const currentOptions = form.values.places.split("§").filter(Boolean);
    if (!currentOptions.includes(query)) {
      const updatedOptions = [...currentOptions, query].join("§");
      form.setFieldValue("places", updatedOptions);
    }
    setSearchQuery("");
    return newOption;
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" &&
      !options.find((option: any) => option.value === searchQuery)
    ) {
      handleCreate(searchQuery);
    }
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <MultiSelect
      data={options}
      value={options.map((option: any) => option.value)}
      onChange={handleChange}
      placeholder={
        "Renseignez un " +
        (form.values.role === UserRole.client ? "lieu" : "domicile")
      }
      creatable
      getCreateLabel={(query) => `+ Rajouter ${query}`}
      onCreate={handleCreate}
      label={form.values.role === UserRole.client ? "Lieux" : "Domicile"}
      description={`Appuyez sur Entrée pour ajouter un ${
        form.values.role === UserRole.client ? "lieu" : "domicile"
      } automatiquement`}
      searchable
      onKeyDown={handleKeyDown}
      onSearchChange={handleSearchChange}
      searchValue={searchQuery}
    />
  );
};

export default DynamicMultiSelect;
