import { ActionIcon, LoadingOverlay, Modal } from "@mantine/core";
import { IconClose } from "../IconType";

const CustomModal = ({
  children,
  setOpened,
  opened,
  loading = false,
  size = "md",
}: {
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  opened: boolean;
  children: React.ReactNode;
  loading?: boolean;
  size?: string;
}) => {
  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      withCloseButton={false}
      styles={{
        overlay: {
          backdropFilter: "blur(5px)",
        },
      }}
      centered
      size={size}
    >
      <LoadingOverlay visible={loading} />
      <ActionIcon
        style={{ position: "absolute", top: 15, right: 15 }}
        onClick={() => setOpened(false)}
      >
        {IconClose}
      </ActionIcon>
      {children}
    </Modal>
  );
};

export default CustomModal;
