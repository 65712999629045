import {
  ColumnDef,
  FilterFn,
  SortingFn,
  sortingFns,
} from "@tanstack/react-table";
import React, { useState, useEffect, useContext } from "react";
// import { Person } from "./makeData";
import { compareItems, RankingInfo } from "@tanstack/match-sorter-utils";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import { FileInfoDto } from "../../dto/googledrive";
import { formatFileSize } from "../../utils";
import moment from "moment";
import "moment/locale/fr";
import IconType, {
  IconCheckBoxChecked,
  IconCheckBoxUnchecked,
  IconDotsVertical,
  IconTrashGray,
  // IconDotsVertical,
} from "../IconType";
import { UserDto, UserRole } from "../../dto/user";
import { ActionIcon, Menu } from "@mantine/core";
import { IconCheck, IconEye, IconTrash } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import State from "../secondary/State";
import { UserContext } from "../../UserContext";
import { State as StateType } from "../../dto/googledrive";

// If at some point there is too much lag with fhis filtering, we can only analyse the relevant columns
export const fuzzyFilter: FilterFn<any> = (
  row,
  columnId,
  filterValue,
  addMeta
) => {
  const search = filterValue.toLowerCase();
  // Convert to String
  const value = String(row.getValue<string>(columnId));
  return value?.toLowerCase().includes(search);
};

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]! as RankingInfo,
      rowB.columnFiltersMeta[columnId]! as RankingInfo
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export type TableMeta = {
  updateData: (rowIndex: number, columnId: string, value: unknown) => void;
};

// Give our default column cell renderer editing superpowers!
export const defaultColumn: Partial<ColumnDef<any>> = {
  cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      (table.options.meta as TableMeta).updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  },
};

const displayDateCell = (cellDate: Date) => {
  if (!cellDate) return null;
  let [date, time] = moment(cellDate)
    .locale("fr")
    .format("D MMM YYYY-HH[h]mm")
    .split("-");

  let dateParts = date.split(" ");
  dateParts[1] =
    dateParts[1].length > 4 ? dateParts[1].slice(0, 4) + "." : dateParts[1];
  date = dateParts.join(" ");

  return (
    <div style={{ display: "flex", justifyContent: "flex-start" }}>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "column",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        <div>{date}</div>
        <div style={{ color: "#656565" }}>{time}</div>
      </div>
    </div>
  );
};

const CellName = ({ row }: { row: FileInfoDto }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  return (
    <span
      style={{
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        cursor:
          row?.mimeType === "application/vnd.google-apps.folder"
            ? "pointer"
            : "default",
        flexGrow: 1,
        width: "100%",
      }}
      onClick={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (row?.mimeType === "application/vnd.google-apps.folder") {
          if (location.pathname.startsWith("/users/")) {
            const [, , username] = location.pathname.split("/");
            navigate(`/users/${username}/folder/${row?.id}`);
          } else if (
            location.pathname === "/" &&
            user?.role !== UserRole.client
          ) {
            navigate("/users/" + row?.name);
          } else navigate("/folder/" + row?.id);
        }
      }}
    >
      <IconType type={row.mimeType} />
      <span
        style={{
          marginLeft: 12,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {row.name}
      </span>
    </span>
  );
};

export const columns: ColumnDef<FileInfoDto>[] = [
  {
    id: "name",
    accessorKey: "name",
    header: ({ table }) => (
      <>
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
        <span style={{ paddingLeft: 12 }}>Tout séléctionner</span>
      </>
    ),
    cell: ({ row }) => (
      <span
        className="px-1"
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
          />
        </div>
        <span style={{ width: 12 }} />
        <CellName row={row.original} />
      </span>
    ),
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorFn: (row) => row.id,
    id: "id",
    // cell: (info) => info.getValue(),
    cell: ({ row }) => (
      <span>
        <State state={row.original.state} />
        {/* {row.original.id} */}
      </span>
    ),
    header: () => "Statut",
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "size",
    header: () => "Taille",
    cell: (info) => <span>{formatFileSize(info.getValue() as number)}</span>,
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    sortingFn: sortingFns.alphanumeric,
  },
  {
    accessorKey: "modifiedTime",
    header: () => "Date",
    cell: (info) => displayDateCell(info.getValue() as Date),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    sortingFn: sortingFns.datetime,
  },
  {
    accessorFn: (row) => row.owner,
    id: "owner",
    cell: ({ row }) => <State state={row.original.owner} />,
    header: () => "Propriétaire",
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "state",
    header: " ",
    footer: (props) => props.column.id,
    cell: ({ row }) => <StateMenu row={row.original} />,
    enableColumnFilter: false,
    enableSorting: false,
  },
];

const StateMenu = ({ row }: { row: FileInfoDto }) => {
  const { user } = useContext(UserContext);
  if (row.state === undefined) return null;

  if (user?.role === "client")
    return (
      <div style={{ display: "flex", whiteSpace: "nowrap" }}>
        <span style={{ width: "100%" }} />
        <ActionIcon
          onClick={(e) => {
            e.stopPropagation();
            const deleteEvent = new CustomEvent("deleteFiles", {
              detail: { fileId: row.id },
            });
            window.dispatchEvent(deleteEvent);
          }}
        >
          {IconTrashGray}
        </ActionIcon>
      </div>
    );
  const tempState: StateType | string = row.state;
  const realState =
    tempState === "drop"
      ? StateType.drop
      : tempState === "processing"
      ? StateType.processing
      : StateType.finished;

  return (
    <div style={{ display: "flex", whiteSpace: "nowrap" }}>
      <span style={{ width: "100%" }} />
      <Menu trigger="hover" openDelay={100} closeDelay={400}>
        <Menu.Target>
          <ActionIcon>{IconDotsVertical}</ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          {realState !== StateType.finished && (
            <Menu.Item
              icon={<IconCheck size={20} />}
              onClick={(e) => {
                e.stopPropagation();
                const validateEvent = new CustomEvent("validateFiles", {
                  detail: {
                    fileId: row.id,
                    realState,
                  },
                });
                window.dispatchEvent(validateEvent);
              }}
            >
              {realState === StateType.drop
                ? "Valider la réception"
                : "Terminer le traitement"}
            </Menu.Item>
          )}
          <Menu.Item
            icon={<IconTrash size={20} />}
            color="red"
            onClick={(e) => {
              e.stopPropagation();
              const deleteEvent = new CustomEvent("deleteFiles", {
                detail: { fileId: row.id },
              });
              window.dispatchEvent(deleteEvent);
            }}
          >
            Supprimer
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export const columnsUsers: ColumnDef<UserDto>[] = [
  {
    accessorKey: "id",
    id: "id",
    cell: (info) => info.getValue(),
    header: () => "#",
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "username",
    id: "username",
    cell: (info) => info.getValue(),
    header: () => "Login",
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "email", // I access the second email in the last column
    id: "email",
    cell: ({ row }) => (
      <span>
        {row.original.email}
        <br />
        {row.original.secoundEmail}
      </span>
    ),
    header: () => "Email",
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "createdAt",
    header: () => "Création",
    cell: (info) => displayDateCell(info.getValue() as Date),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    sortingFn: sortingFns.alphanumeric,
  },
  {
    accessorKey: "updatedAt",
    header: () => "Modification",
    cell: (info) => displayDateCell(info.getValue() as Date),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    sortingFn: sortingFns.alphanumeric,
  },
  {
    accessorKey: "lastLogin",
    header: () => "Derniére connexion",
    cell: (info) => displayDateCell(info.getValue() as Date),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    sortingFn: sortingFns.alphanumeric,
  },
  {
    accessorFn: (row) => row.role,
    id: "role",
    cell: (info) => {
      const role = info.getValue() as string;
      return role === "administrateur"
        ? "Admin"
        : role === "membre"
        ? "Rédacteur"
        : "Client";
    },
    header: () => "Role",
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "activated",
    header: () => "Compte activé",
    cell: (info) => (
      <span style={{ marginLeft: 34 }}>
        {info.getValue() ? IconCheckBoxChecked : IconCheckBoxUnchecked}
      </span>
    ),
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
  {
    accessorKey: "secoundEmail",
    header: () => "",
    cell: ({ row }) => <EmailMenu username={row.original.username || ""} />,
    footer: (props) => props.column.id,
    enableColumnFilter: false,
    enableSorting: false,
  },
];

const EmailMenu = ({ username }: { username: string }) => {
  const navigate = useNavigate();

  return (
    <ActionIcon onClick={() => navigate(`/users/${username}`)}>
      <IconEye size={20} color="#333333" />
    </ActionIcon>
  );
};

export const getTableMeta = (
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  skipAutoResetPageIndex: () => void
) =>
  ({
    updateData: (rowIndex, columnId, value) => {
      // Skip age index reset until after next rerender
      skipAutoResetPageIndex();
      setData((old) =>
        old.map((row, index) => {
          if (index !== rowIndex) return row;

          return {
            ...old[rowIndex]!,
            [columnId]: value,
          };
        })
      );
    },
  } as TableMeta);
