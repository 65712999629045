import { Box, Text, Title, Loader, Table } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { HeaderAction } from "../components/HeaderAction";
import { UserContext } from "../UserContext";
import { getTaskStatistics } from "../apiRoutes";
import { useApi } from "../useApi";
import { format, parseISO } from "date-fns";
import { fr } from "date-fns/locale";

interface StatisticItem {
  week: string;
  averageDays: number;
  totalTasks: number;
}

const Statistics = () => {
  const { user } = useContext(UserContext);
  const api = useApi();
  const [statistics, setStatistics] = useState<StatisticItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await getTaskStatistics(api);
        setStatistics(response.data);
      } catch (error) {
        console.error("Failed to fetch statistics:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStatistics();
  }, [api]);

  const columns = [
    { key: "week", label: "Semaine de livraison" },
    { key: "averageDays", label: "Délai moyen (jours)" },
    { key: "totalTasks", label: "Nombre total de tâches" },
  ];

  const formatWeek = (weekDate: string) => {
    const date = parseISO(weekDate);
    const formattedDate = format(date, "'S'II - dd/MM/yyyy", { locale: fr });
    return formattedDate;
  };

  return (
    <Box bg={"#F9FAFB"} pb={1}>
      <HeaderAction planning={user?.isAdminPlanning} />
      <Box p={30} bg="#F9FAFB">
        <Title>Statistiques</Title>
        <Text>
          Temps moyen par semaine entre la réunion/dépose enregistrement et la
          livraison
        </Text>
      </Box>

      {loading ? (
        <Loader />
      ) : (
        <Table striped highlightOnHover>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {statistics.map((item) => (
              <tr key={item.week}>
                <td>{formatWeek(item.week)}</td>
                <td>{item.averageDays.toFixed(2)}</td>
                <td>{item.totalTasks}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Box>
  );
};

export default Statistics;
