import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  LoadingOverlay,
  Modal,
  SegmentedControl,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { postDeliverBillDelete, postTasks } from "../apiRoutes";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { TaskDto, TaskType } from "../dto/user";
import { AxiosInstance } from "axios";
import {
  calculateExpectedTime,
  combineDateAndTime,
  convertToClockTime,
  isTaskTypeFixed,
  isTaskTypeOnlyPlanning,
  ceilToNearestHalf,
} from "../utils";
import { useDisclosure } from "@mantine/hooks";
import MultipleDatesInput from "./MultipleDatesInput";

const TimeSelectOptions = [
  { value: "00:00:00.000Z", label: "0:00" },
  { value: "00:30:00.000Z", label: "0:30" },
  { value: "01:00:00.000Z", label: "1:00" },
  { value: "01:30:00.000Z", label: "1:30" },
  { value: "02:00:00.000Z", label: "2:00" },
  { value: "02:30:00.000Z", label: "2:30" },
  { value: "03:00:00.000Z", label: "3:00" },
  { value: "03:30:00.000Z", label: "3:30" },
  { value: "04:00:00.000Z", label: "4:00" },
  { value: "04:30:00.000Z", label: "4:30" },
  { value: "05:00:00.000Z", label: "5:00" },
  { value: "05:30:00.000Z", label: "5:30" },
  { value: "06:00:00.000Z", label: "6:00" },
  { value: "06:30:00.000Z", label: "6:30" },
  { value: "07:00:00.000Z", label: "7:00" },
];

const TaskDurationSelectOptions = [
  { value: "0.5", label: "0:30" },
  { value: "1", label: "1:00" },
  { value: "1.5", label: "1:30" },
  { value: "2", label: "2:00" },
  { value: "2.5", label: "2:30" },
  { value: "3", label: "3:00" },
  { value: "3.5", label: "3:30 - 0.5 jour" },
  { value: "4", label: "4:00" },
  { value: "4.5", label: "4:30" },
  { value: "5", label: "5:00" },
  { value: "5.5", label: "5:30" },
  { value: "6", label: "6:00" },
  { value: "6.5", label: "6:30" },
  { value: "7", label: "7:00 - 1 jour" },
  { value: "7.5", label: "7:30" },
  { value: "8", label: "8:00" },
  { value: "8.5", label: "8:30" },
  { value: "9", label: "9:00" },
  { value: "9.5", label: "9:30" },
  { value: "10", label: "10:00" },
  { value: "10.5", label: "10:30 - 1.5 jour" },
  { value: "11", label: "11:00" },
  { value: "11.5", label: "11:30" },
  { value: "12", label: "12:00" },
  { value: "12.5", label: "12:30" },
  { value: "13", label: "13:00" },
  { value: "13.5", label: "13:30" },
  { value: "14", label: "14:00 - 2 jours" },
  { value: "14.5", label: "14:30" },
  { value: "15", label: "15:00" },
  { value: "15.5", label: "15:30" },
  { value: "16", label: "16:00" },
  { value: "16.5", label: "16:30" },
  { value: "17", label: "17:00" },
  { value: "17.5", label: "17:30 - 2.5 jours" },
  { value: "18", label: "18:00" },
  { value: "18.5", label: "18:30" },
  { value: "19", label: "19:00" },
  { value: "19.5", label: "19:30" },
  { value: "20", label: "20:00" },
  { value: "20.5", label: "20:30" },
  { value: "21", label: "21:00 - 3 jours" },
  { value: "21.5", label: "21:30" },
  { value: "22", label: "22:00" },
  { value: "22.5", label: "22:30" },
  { value: "23", label: "23:00" },
  { value: "23.5", label: "23:30" },
  { value: "24", label: "24:00" },
  { value: "24.5", label: "24:30 - 3.5 jours" },
  { value: "25", label: "25:00" },
  { value: "25.5", label: "25:30" },
  { value: "26", label: "26:00" },
  { value: "26.5", label: "26:30" },
  { value: "27", label: "27:00" },
  { value: "27.5", label: "27:30" },
  { value: "28", label: "28:00 - 4 jours" },
  { value: "28.5", label: "28:30" },
  { value: "29", label: "29:00" },
  { value: "29.5", label: "29:30" },
  { value: "30", label: "30:00" },
  { value: "30.5", label: "30:30" },
  { value: "31", label: "31:00" },
  { value: "31.5", label: "31:30 - 4.5 jours" },
  { value: "32", label: "32:00" },
  { value: "32.5", label: "32:30" },
  { value: "33", label: "33:00" },
  { value: "33.5", label: "33:30" },
  { value: "34", label: "34:00" },
  { value: "34.5", label: "34:30" },
  { value: "35", label: "35:00 - 5 jours" },
  { value: "35.5", label: "35:30" },
  { value: "36", label: "36:00" },
  { value: "36.5", label: "36:30" },
  { value: "37", label: "37:00" },
  { value: "37.5", label: "37:30" },
  { value: "38", label: "38:00" },
  { value: "38.5", label: "38:30 - 5.5 jours" },
  { value: "39", label: "39:00" },
  { value: "39.5", label: "39:30" },
  { value: "40", label: "40:00" },
  { value: "40.5", label: "40:30" },
  { value: "41", label: "41:00" },
  { value: "41.5", label: "41:30" },
  { value: "42", label: "42:00 - 6 jours" },
  { value: "42.5", label: "42:30" },
  { value: "43", label: "43:00" },
  { value: "43.5", label: "43:30" },
  { value: "44", label: "44:00" },
  { value: "44.5", label: "44:30" },
  { value: "45", label: "45:00" },
  { value: "45.5", label: "45:30 - 6.5 jours" },
  { value: "46", label: "46:00" },
  { value: "46.5", label: "46:30" },
  { value: "47", label: "47:00" },
  { value: "47.5", label: "47:30" },
  { value: "48", label: "48:00" },
  { value: "48.5", label: "48:30" },
  { value: "49", label: "49:00 - 7 jours" },
];

const TaskForm = ({
  opened,
  open,
  close,
  isPlanning,
  isRecordings,
  api,
  setRefreshCounter,
  users = [],
  redactors,
  setIsPlanning,
  tasks,
  resetFormCounter,
}: {
  opened: boolean;
  open: () => void;
  close: () => void;
  isPlanning: boolean;
  isRecordings: boolean;
  api: AxiosInstance;
  setRefreshCounter: React.Dispatch<React.SetStateAction<number>>;
  users?: {
    label: string;
    value: string;
    places: string;
    processinglevel: string;
    averagmeetingduration: number;
  }[];
  redactors: {
    label: string;
    value: string;
  }[];
  setIsPlanning?: React.Dispatch<React.SetStateAction<boolean>>;
  tasks: TaskDto[];
  resetFormCounter?: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [planMultipleDates, setPlanMultipleDates] = useState(false);
  const [multipleDates, setMultipleDates] = useState<string[]>([]);
  const [selectedCustomerPlaces, setSelectedCustomerPlaces] =
    React.useState("");
  const [
    openedDeliverBillModal,
    { open: openDeliverBillModal, close: closeDeliverBillModal },
  ] = useDisclosure(false);
  const [deliverBillDeleteModalType, setDeliverBillDeleteModalType] =
    React.useState<"Facturer" | "Livrer" | "Supprimer">("Facturer");
  const form = useForm<
    TaskDto & { schedulingType: "fixed" | "auto" | "end-fixed" | "begin-fixed" }
  >({
    initialValues: {
      meetingDate: "",
      format: "",
      duration: "",
      comment: "",
      commentRedaction: "",
      commentRedactor: "",
      instructions: "",
      place: "",
      type: isRecordings ? TaskType.recordingWriting : TaskType.meeting,
      state: undefined,
      date: new Date(),
      customerId: undefined,
      redactorId: undefined,
      startDate: undefined,
      startTime: undefined,
      endDate: undefined,
      endTime: undefined,
      slotDuration: undefined,
      schedulingType: "fixed",
      deliveryDate: undefined,
      timeAtTheCustomer: undefined,
    },
    validate: {
      customerId: (value, values) =>
        !value &&
        values.type !== TaskType.break &&
        values.type !== TaskType.otherFixed &&
        values.type !== TaskType.otherFlexible
          ? "Champ requis"
          : null,
      redactorId: (value, values) =>
        (values.type === TaskType.break ||
          values.type === TaskType.otherFixed ||
          values.type === TaskType.otherFlexible) &&
        !value
          ? "Champ requis"
          : null,
      slotDuration: (value, values) =>
        (values.schedulingType !== "fixed" || !isTaskTypeFixed(values.type)) &&
        !value &&
        values.redactorId
          ? "Champ requis"
          : null,
      startTime: (value, values) =>
        (values.schedulingType === "begin-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
      startDate: (value, values) =>
        (values.schedulingType === "begin-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
      endTime: (value, values) =>
        (values.schedulingType === "end-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
      endDate: (value, values) =>
        (values.schedulingType === "end-fixed" && value === undefined) ||
        (values.redactorId &&
          values.schedulingType === "fixed" &&
          value === undefined)
          ? "Champ requis"
          : null,
    },
  });

  useEffect(() => {
    const handlePlanTask = (e: any) => {
      const { taskId, type, buttonType } = e.detail;

      const ensureDateObject = (date: any): Date | undefined => {
        return date ? new Date(date) : undefined;
      };
      const adjustToLocalDate = (date: Date | undefined) => {
        if (!date) return undefined;
        const timezoneOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() + timezoneOffset);
      };
      const extractStartTime = (date: Date | undefined) => {
        if (!date) return undefined;
        const timePart = date.toISOString().split("T")[1].split(".")[0];
        return timePart + ".000Z";
      };

      if (
        taskId === 0 &&
        (type === TaskType.break ||
          type === TaskType.otherFlexible ||
          type === TaskType.otherFixed)
      ) {
        console.log("type", type);
        form.reset();
        form.setFieldValue("type", type);
        form.setFieldValue("startTime", "00:00:00.000Z");
        form.setFieldValue("endTime", "07:00:00.000Z");
        if (type === TaskType.otherFlexible) {
          form.setFieldValue("schedulingType", "auto");
        }
      } else {
        const taskToPlan = tasks.find((task) => task.id === taskId);
        const estimatedTime = JSON.stringify(
          ceilToNearestHalf(
            calculateExpectedTime(
              users?.find(
                (user) => user.value === taskToPlan?.customerId?.toString()
              )?.processinglevel ?? "",
              taskToPlan?.duration
            )
          )
        );
        console.log("estimatedTime", estimatedTime, taskToPlan?.duration);
        console.log(
          "taskIdd",
          taskId,
          taskToPlan,
          tasks.length,
          taskToPlan?.type,
          estimatedTime,
          taskToPlan?.slotDuration,
          typeof taskToPlan?.slotDuration,
          taskToPlan?.slotDuration ??
            (taskToPlan?.type === TaskType.meeting &&
            taskToPlan?.customer?.averageMeetingDuration
              ? taskToPlan?.customer?.averageMeetingDuration?.toString() ??
                "3.5"
              : "3.5")
        );
        let slotDuration = undefined;
        if (taskToPlan?.slotDuration) {
          slotDuration = taskToPlan.slotDuration;
        } else if (
          taskToPlan?.type === TaskType.meeting &&
          taskToPlan?.customer?.averageMeetingDuration
        ) {
          slotDuration = taskToPlan.customer.averageMeetingDuration.toString();
        } else if (
          taskToPlan?.type === TaskType.meeting &&
          taskToPlan?.customer?.averageMeetingDuration
        ) {
          slotDuration = "3.5";
        } else {
          slotDuration = estimatedTime;
        }
        console.log("slotDuration", slotDuration);
        form.setValues({
          ...taskToPlan,
          comment: taskToPlan?.comment ?? undefined,
          commentRedaction: taskToPlan?.commentRedaction ?? undefined,
          meetingDate: taskToPlan?.meetingDate ?? undefined,
          state: taskToPlan?.state ?? undefined,
          redactorId: taskToPlan?.redactorId?.toString(),
          customerId: taskToPlan?.customerId?.toString(),
          startDate: adjustToLocalDate(ensureDateObject(taskToPlan?.startDate)),
          endDate: adjustToLocalDate(ensureDateObject(taskToPlan?.endDate)),
          startTime: extractStartTime(ensureDateObject(taskToPlan?.startDate)),
          endTime: extractStartTime(ensureDateObject(taskToPlan?.endDate)),
          schedulingType: isTaskTypeFixed(taskToPlan?.type) ? "fixed" : "auto",
          slotDuration,
        });
        setSelectedCustomerPlaces(taskToPlan?.customer?.places ?? "");
      }

      setIsPlanning && setIsPlanning(true);
      if (buttonType === "Livrer" || buttonType === "Facturer") {
        openDeliverBillModal();
        setDeliverBillDeleteModalType(buttonType);
      } else {
        open();
      }
    };

    window.addEventListener("planTask", handlePlanTask);
    return () => {
      window.removeEventListener("planTask", handlePlanTask);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, tasks]);

  useEffect(() => {
    form.reset();
    form.setFieldValue(
      "type",
      isRecordings ? TaskType.recordingWriting : TaskType.meeting
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFormCounter, isRecordings]);

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Modal
        opened={openedDeliverBillModal}
        onClose={closeDeliverBillModal}
        title={
          deliverBillDeleteModalType === "Facturer"
            ? "Confirmer la facturation"
            : deliverBillDeleteModalType === "Supprimer"
            ? "Confirmer la suppression définitive de la tâche"
            : "Confirmer la livraison et mettre à jour la date de livraison"
        }
      >
        <Center>
          <Button
            color={
              deliverBillDeleteModalType === "Supprimer" ? "red" : undefined
            }
            onClick={async () => {
              setLoading(true);
              await postDeliverBillDelete(
                api,
                form.values.id ?? 0,
                deliverBillDeleteModalType
              );
              setRefreshCounter((e) => e + 1);
              form.reset();
              closeDeliverBillModal();
              setLoading(false);
            }}
          >
            {deliverBillDeleteModalType === "Facturer"
              ? "Confirmer"
              : deliverBillDeleteModalType === "Supprimer"
              ? "Supprimer"
              : "Mettre à jour la date de livraison"}
          </Button>
        </Center>
      </Modal>
      <Modal
        opened={opened}
        onClose={close}
        title={
          (isPlanning
            ? form.values.type === TaskType.otherFixed
              ? "Plannifier une tâche Autre Fixe"
              : form.values.type === TaskType.otherFlexible
              ? "Plannifier une tâche Autre Flexible"
              : "Planifier"
            : isRecordings
            ? "Nouvel enregistrement à planifier"
            : "Nouvelle réunion à planifier") +
          (form.values.id ? ` (${JSON.stringify(form.values.id)})` : "")
        }
      >
        <form
          onSubmit={form.onSubmit(async (values) => {
            const startDate = combineDateAndTime(
              values.startDate,
              values.startTime
            );
            const endDate = combineDateAndTime(values.endDate, values.endTime);
            if (
              startDate &&
              endDate &&
              endDate <= startDate &&
              form.values.schedulingType === "fixed"
            ) {
              alert("La fin de la tâche doit être après le début de la tâche");
              return;
            }
            const redactionTaskTimeForMeeting = ceilToNearestHalf(
              calculateExpectedTime(
                users?.find((user) => user.value === form.values.customerId)
                  ?.processinglevel ?? "",
                convertToClockTime(
                  users?.find((user) => user.value === form.values.customerId)
                    ?.averagmeetingduration ?? 0
                )
              )
            );
            const submitData = {
              ...values,
              startDate,
              endDate,
              redactionTaskTimeForMeeting,
              redactorId: values.redactorId ? +values.redactorId : undefined,
              customerId: values.customerId ? +values.customerId : undefined,
              slotDuration: values.redactorId ? values.slotDuration : undefined,
              multipleDates: planMultipleDates ? multipleDates : undefined,
            };
            delete submitData.startTime;
            delete submitData.endTime;
            console.log("👾", submitData);
            setLoading(true);
            const ret = await postTasks(api, submitData);
            setLoading(false);
            if (ret.data === "interferance") {
              alert(
                "La tâche que vous essayez de planifier chevauche une autre tâche qui ne peut pas être bougée."
              );
            } else if (ret.data === "interferance_flex") {
              alert(
                "Vous ne pouvez pas planifier une tâche flexible ici. La tâche flexible doit soit démarrer au début d'une autre tâche flexible, soit dans un créneau disponible."
              );
            } else {
              setRefreshCounter((e) => e + 1);
              form.reset();
              close();
              setPlanMultipleDates(false);
              setMultipleDates([]);
            }
          })}
        >
          {/* {JSON.stringify(form.values.type)} */}
          {(users ?? []).length > 0 && (
            <Select
              label="Code client"
              placeholder="Ajouter le code client"
              {...form.getInputProps("customerId")}
              data={users ?? []}
              onChange={(value) => {
                const user = users?.find((user) => user.value === value);
                console.log(user, value);
                form.setFieldValue("customerId", value ?? "");
                setSelectedCustomerPlaces(user?.places ?? "");
              }}
              mt={10}
              searchable
              withAsterisk
            />
          )}
          {!isTaskTypeOnlyPlanning(form.values.type) && (
            <Select
              label="Statut de la tâche"
              placeholder="Sélectionnez le statut de la tâche"
              {...form.getInputProps("state")}
              data={[
                { value: "documentsReceived", label: "📄 Documents reçus" },
                { value: "readyToStart", label: "▶️ Prêt à démarrer" },
                {
                  value: "validatedByRedactor",
                  label: "✔️ Validé par le rédacteur",
                },
                { value: "delivered", label: "✉️ Livré" },
                { value: "billed", label: "✅ Facturé" },
              ]}
              mt={10}
              searchable
              clearable
            />
          )}
          {form.values.customerId &&
          !isRecordings &&
          form.values.type === TaskType.meeting ? (
            <Select
              label="Lieu"
              data={selectedCustomerPlaces.split("§").map((place) => ({
                value: place,
                label: place,
              }))}
              placeholder="Sélectionnez l'emplacement de la réunion"
              {...form.getInputProps("place")}
              mt={10}
              searchable
            />
          ) : null}
          {!isPlanning && form.values.type === "meeting" && (
            <Checkbox
              mt={10}
              label="Planifer plusieurs dates"
              checked={planMultipleDates}
              onChange={(event) =>
                setPlanMultipleDates(event.currentTarget.checked)
              }
            />
          )}
          <Flex gap={14}>
            {isRecordings && (
              <TimeInput
                label="Durée de l'enregistrement"
                placeholder="Ajouter le durée de l'enregistrement"
                description="exemple: 01:35"
                {...form.getInputProps("duration")}
                mt={10}
                w="100%"
              />
            )}
            {!isTaskTypeOnlyPlanning(form.values.type) &&
              (!planMultipleDates ||
                isPlanning ||
                form.values.type !== "meeting") && (
                <TextInput
                  label="Date de la réunion"
                  description="exemple: 1005"
                  placeholder="Ajouter la date de la réunion (exemple 3001)"
                  {...form.getInputProps("meetingDate")}
                  mt={10}
                  w="100%"
                />
              )}
          </Flex>
          {planMultipleDates && !isPlanning && (
            <MultipleDatesInput
              multipleDates={multipleDates}
              setMultipleDates={setMultipleDates}
            />
          )}
          <Flex gap={14}>
            <TextInput
              label="Comm. Administrateurs"
              placeholder="Ajouter un commentaire administrateurs"
              {...form.getInputProps("comment")}
              mt={10}
              w="100%"
            />
            <TextInput
              label="Commentaire Rédacteurs"
              placeholder="Ajouter un commentaire rédacteurs"
              {...form.getInputProps("commentRedaction")}
              mt={10}
              w="100%"
            />
          </Flex>
          {isPlanning && (
            <>
              {!isTaskTypeOnlyPlanning(form.values.type) && <Divider my="sm" />}
              {TaskType.meeting === form.values.type &&
                form.values.redactorId && (
                  <Flex gap={14}>
                    <TimeInput
                      label="Temps de présence"
                      placeholder="Ajouter le "
                      {...form.getInputProps("timeAtTheCustomer")}
                      mt={10}
                      w="100%"
                    />
                    <TimeInput
                      label="Durée de la réunion"
                      placeholder="Ajouter le "
                      {...form.getInputProps("duration")}
                      mt={10}
                      w="100%"
                    />
                    <TextInput
                      label="Commentaire du rédacteur"
                      placeholder="Ajouter un commentaire du rédacteur"
                      {...form.getInputProps("commentRedactor")}
                      mt={10}
                      w="100%"
                    />
                  </Flex>
                )}
              <Select
                label="Rédacteur"
                placeholder="Choisissez un rédacteur"
                {...form.getInputProps("redactorId")}
                data={redactors}
                mt={10}
                searchable
                withAsterisk
                zIndex={
                  isTaskTypeOnlyPlanning(form.values.type) &&
                  !form.values.redactorId
                    ? 1000000
                    : undefined
                }
                maxDropdownHeight={
                  isTaskTypeOnlyPlanning(form.values.type) &&
                  !form.values.redactorId
                    ? 150
                    : undefined
                }
              />
              {form.values.redactorId && (
                <>
                  {form.values.type !== "break" && (
                    <Text style={{ fontSize: 14 }} mt={10}>
                      {users?.length === 0 // page planning
                        ? `Temps créneau : ${form.values.slotDuration} heures`
                        : form.values.type === TaskType.meeting
                        ? `Durée habituelle de la réunion : ${
                            users?.find(
                              (user) => user.value === form.values.customerId
                            )?.averagmeetingduration
                          } heures`
                        : `Temps estimé : ${calculateExpectedTime(
                            users?.find(
                              (user) => user.value === form.values.customerId
                            )?.processinglevel ?? "",
                            form.values.duration
                          )} heures`}
                    </Text>
                  )}
                  {form.values.type === TaskType.meeting &&
                    users?.length !== 0 && (
                      <Box>
                        <Text style={{ fontSize: 14 }}>
                          Estimation du temps de rédaction :{" "}
                          {calculateExpectedTime(
                            users?.find(
                              (user) => user.value === form.values.customerId
                            )?.processinglevel ?? "",
                            convertToClockTime(
                              users?.find(
                                (user) => user.value === form.values.customerId
                              )?.averagmeetingduration ?? 0
                            )
                          )}
                          {" heures"}
                        </Text>
                        <Text style={{ fontSize: 14 }}>
                          Création d'un temps de rédaction de{" "}
                          {ceilToNearestHalf(
                            calculateExpectedTime(
                              users?.find(
                                (user) => user.value === form.values.customerId
                              )?.processinglevel ?? "",
                              convertToClockTime(
                                users?.find(
                                  (user) =>
                                    user.value === form.values.customerId
                                )?.averagmeetingduration ?? 0
                              )
                            )
                          )}
                          {" heures"}
                        </Text>
                      </Box>
                    )}
                  {/* <Flex>
              <Checkbox label="Création d'un" mt={3} />
            </Flex> */}
                  <Text style={{ fontSize: 14 }} mt={10}>
                    Planification
                    {form.values.type === TaskType.meeting
                      ? " de la réunion"
                      : ""}
                  </Text>
                  <SegmentedControl
                    data={[
                      ...(isTaskTypeFixed(form.values.type)
                        ? [{ label: "Fixé", value: "fixed" }]
                        : [{ label: "Automatique", value: "auto" }]),
                      { label: "Début fix", value: "begin-fixed" },
                      // { label: "Fin fix", value: "end-fixed" },
                    ]}
                    {...form.getInputProps("schedulingType")}
                    fullWidth
                  />
                  {(form.values.schedulingType !== "fixed" ||
                    !isTaskTypeFixed(form.values.type)) && (
                    <Select
                      label="Durée de la tâche"
                      placeholder="Temps alloué pour réaliser la tâche"
                      {...form.getInputProps("slotDuration")}
                      data={TaskDurationSelectOptions}
                      mt={10}
                      searchable
                      withAsterisk
                    />
                  )}
                  {(form.values.schedulingType === "fixed" ||
                    form.values.schedulingType === "begin-fixed") && (
                    <Box>
                      <Text style={{ fontSize: 14 }} mt={10}>
                        Date de début de la tâche
                      </Text>
                      <Flex>
                        <DatePicker
                          placeholder="Date de début de la tâche"
                          {...form.getInputProps("startDate")}
                          mx="auto"
                          mt={10}
                          excludeDate={(date) =>
                            date.getDay() === 0 || date.getDay() === 6
                          }
                        />
                        <Select
                          label="Heure de démarage de la tâche"
                          placeholder="Heure de démarage de la tâche"
                          {...form.getInputProps("startTime")}
                          data={TimeSelectOptions}
                          mt={10}
                          searchable
                          withAsterisk
                        />
                      </Flex>
                    </Box>
                  )}
                  {(form.values.schedulingType === "fixed" ||
                    form.values.schedulingType === "end-fixed") && (
                    <Box>
                      <Text style={{ fontSize: 14 }} mt={10}>
                        Date de fin de la tâche
                      </Text>
                      <Flex>
                        <DatePicker
                          placeholder="Date de fin de la tâche"
                          {...form.getInputProps("endDate")}
                          mx="auto"
                          mt={10}
                          excludeDate={(date) =>
                            date.getDay() === 0 || date.getDay() === 6
                          }
                        />
                        <Select
                          label="Heure de fin de la tâche"
                          placeholder="Heure de fin de la tâche"
                          {...form.getInputProps("endTime")}
                          data={TimeSelectOptions}
                          mt={10}
                          searchable
                          withAsterisk
                        />
                      </Flex>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
          <Center style={{ gap: 16 }}>
            <Button onClick={close} mt={16} variant="default">
              Annuler
            </Button>
            {form.values.id && (
              <Button
                onClick={() => {
                  setDeliverBillDeleteModalType("Supprimer");
                  openDeliverBillModal();
                  close();
                }}
                mt={16}
                color="red"
              >
                Supprimer
              </Button>
            )}
            <Button type="submit" mt={16}>
              {isPlanning
                ? form.values.redactorId
                  ? "Ajouter au planning"
                  : "Modifier"
                : "Ajouter"}
            </Button>
          </Center>
        </form>
      </Modal>
    </>
  );
};

export default TaskForm;
