import React from "react";
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getGroupedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  GroupingState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useSkipper } from "./hooks";
import { defaultColumn, fuzzyFilter, getTableMeta } from "./tableFunctions";
import CustomTable from "./CustomTable2";
import { ActionIcon, Button, Flex, Input, Text } from "@mantine/core";
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
  IconSearch,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { TaskState } from "../../dto/user";

const PAGE_SIZE = 100;

const TableInit = ({
  data,
  setData,
  loading,
  columns,
  initialSorting = [],
  redirect = undefined,
  hideAddButton = false,
  open,
  specialButton,
  isTasks,
}: {
  data: any[];
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  loading: boolean;
  columns: any[];
  initialSorting?: any;
  redirect?: string;
  hideAddButton?: boolean;
  open?: () => void;
  specialButton?: { title: string; onClick: () => void };
  isTasks?: boolean;
}) => {
  const [grouping, setGrouping] = React.useState<GroupingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [columnPinning, setColumnPinning] = React.useState({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const [sorting, setSorting] = React.useState<SortingState>(initialSorting);
  const [taskFilter, setTaskFilter] = React.useState("planifier");
  const navigate = useNavigate();

  const filteredData = React.useMemo(() => {
    if (taskFilter === "planifier") {
      return data.filter((task) => !task.startDate || !task.endDate);
    } else if (taskFilter === "billed") {
      return data.filter((task) => task.state === TaskState.billed);
    } else if (taskFilter === "delivered") {
      return data.filter((task) => task.state === TaskState.delivered);
    } else {
      //planned
      return data.filter(
        (task) =>
          task.state !== TaskState.billed &&
          task.state !== TaskState.delivered &&
          task.startDate &&
          task.endDate
      );
    }
  }, [data, taskFilter]);

  const table = useReactTable({
    data: filteredData, // Use the filtered data here
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    autoResetPageIndex,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    onGroupingChange: setGrouping,
    onColumnPinningChange: setColumnPinning,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    meta: getTableMeta(setData, skipAutoResetPageIndex),
    state: {
      grouping,
      sorting,
      columnFilters,
      globalFilter,
      columnPinning,
      rowSelection,
    },
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
    initialState: {
      pagination: {
        pageSize: PAGE_SIZE,
      },
    },
  });

  return (
    <Flex direction={"column"}>
      <div
        style={{
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "white",
          padding: "12px 16px",
          borderRight: "1px solid #EAECF0",
          borderLeft: "1px solid #EAECF0",
          borderTop: "1px solid #EAECF0",
        }}
      >
        <Input
          placeholder="Rechercher"
          style={{ width: 300 }}
          styles={{ input: { height: 44 } }}
          icon={<IconSearch />}
          value={globalFilter ?? ""}
          onChange={(event) => setGlobalFilter(event.currentTarget.value)}
        />
        {isTasks && (
          <Button.Group>
            <Button
              variant={taskFilter === "planifier" ? "filled" : "default"}
              onClick={() => setTaskFilter("planifier")}
            >
              À planifier
            </Button>
            <Button
              variant={taskFilter === "planned" ? "filled" : "default"}
              onClick={() => setTaskFilter("planned")}
            >
              À livrer
            </Button>
            <Button
              variant={taskFilter === "delivered" ? "filled" : "default"}
              onClick={() => setTaskFilter("delivered")}
            >
              Livré
            </Button>
            <Button
              variant={taskFilter === "billed" ? "filled" : "default"}
              onClick={() => setTaskFilter("billed")}
            >
              Facturé
            </Button>
          </Button.Group>
        )}
        <div>
          {specialButton && (
            <Button onClick={specialButton.onClick} variant="light" mr={16}>
              {specialButton.title}
            </Button>
          )}
          {!hideAddButton && (
            <Button
              onClick={() => {
                if (open) open();
                else
                  navigate(
                    window.location.pathname === "/clients"
                      ? "/newuser?role=customer"
                      : "/newuser?role=redactor"
                  );
              }}
            >
              Ajouter
            </Button>
          )}
        </div>
      </div>
      <div style={{ overflowX: "auto", display: "flex" }}>
        <CustomTable
          table={table}
          loading={loading}
          columns={columns}
          redirect={redirect}
        />
      </div>
      {data.length > PAGE_SIZE && (
        <div
          className="flex items-center gap-2"
          style={{
            display: "flex",
            maxHeight: 25,
            marginTop: 15,
            justifyContent: "center",
            gap: 5,
          }}
        >
          <ActionIcon
            variant="default"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <IconChevronsLeft size="1rem" />
          </ActionIcon>

          <ActionIcon
            variant="default"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <IconChevronLeft size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <IconChevronRight size="1rem" />
          </ActionIcon>
          <ActionIcon
            variant="default"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <IconChevronsRight size="1rem" />
          </ActionIcon>
          <Text style={{ marginTop: 3, fontSize: 14, color: "#333333" }}>
            Page {table.getState().pagination.pageIndex + 1} sur{" "}
            {table.getPageCount()}
          </Text>

          {/* <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                table.setPageIndex(page);
              }}
              className="border p-1 rounded w-16"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 50, 100, 250, 1000].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select> */}
        </div>
      )}
    </Flex>
  );
};

export default TableInit;
