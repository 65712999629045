import CustomModal from "./CustomModal";
import { Title } from "@mantine/core";

const HelpModal = ({
  opened,
  setOpened,
}: {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <CustomModal opened={opened} setOpened={setOpened} size="lg">
      <Title
        order={3}
        mt={10}
        mb={10}
        style={{ textAlign: "center", fontSize: 20 }}
        color="#333333"
      >
        Aide
      </Title>
      <div
        style={{
          borderRadius: 12,
          border: "1px solid #2E6DA4",
          fontWeight: 700,
          color: "#2E6DA4",
          padding: 16,
          margin: 20,
          marginBottom: 30,
          backgroundColor: "#F5FBFF",
        }}
      >
        <div>
          Avec votre enregistrement, n'oubliez pas SVP de nous envoyer :
        </div>
        <ul>
          <li>l'ordre du jour de la réunion</li>
          <li>la liste des participants à jour</li>
        </ul>
      </div>
      <div style={{ marginRight: 20, marginLeft: 20, fontSize: 14 }}>
        <div style={{ color: "#475467", fontWeight: 700 }}>
          Pour nous envoyer un fichier :
        </div>
        <ol style={{}}>
          <li>cliquez sur « Cliquez pour importer ou glissez et déposez »</li>
          <li>
            cliquez ensuite sur « Choisissez un fichier » pour sélectionner le
            fichier à nous envoyer
          </li>
          <li>
            enfin cliquez sur « Envoyer » pour lancer le téléchargement ; une
            barre de progression s’affiche pour vous indiquer le temps de
            téléchargement restant
          </li>
        </ol>
        <div style={{ color: "#475467", fontWeight: 700 }}>
          Pour télécharger un fichier :
        </div>
        <ol style={{}}>
          <li>cocher la case du ou des fichier(s) concerné(s)</li>
          <li>
            puis cliquer sur « télécharger », ce qui lancera le téléchargement
          </li>
        </ol>
      </div>
    </CustomModal>
  );
};
export default HelpModal;
