import React from "react";
import { State as StateType } from "../../dto/googledrive";

const State = ({ state }: { state: StateType | undefined | string }) => {
  if (!state) return null;
  let realState;
  let color;
  switch (state) {
    case "drop":
      realState = StateType.drop;
      color = "#027A48";
      break;
    case "processing":
      realState = StateType.processing;
      color = "#175CD3";
      break;
    case "Compte Rendu":
      realState = "Compte Rendu";
      color = "#656565";
      break;
    case "finished":
      realState = StateType.finished;
      color = "#656565";
      break;
    default:
      realState = state;
      color = "#175CD3";
      break;
  }

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        padding: "0 7px",
        border: `1.5px solid ${color}`,
        borderRadius: 12,
      }}
    >
      <div
        style={{
          height: "6px",
          width: "6px",
          borderRadius: "6px",
          backgroundColor: color,
          marginRight: "5px",
        }}
      ></div>
      <div style={{ color: color, whiteSpace: "nowrap" }}>{realState}</div>
    </div>
  );
};

export default State;
