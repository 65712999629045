import { RowModel } from "@tanstack/react-table";
import { FileInfoDto } from "../../dto/googledrive";
import CustomModal from "./CustomModal";
import { Button, Select, Title } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { useApi } from "../../useApi";
import { getUserFolders } from "../../apiRoutes";
import { useParams } from "react-router-dom";
import { UserContext } from "../../UserContext";

const MoveModal = ({
  handleMove,
  opened,
  setOpened,
  loading,
  selectedRows,
}: {
  handleMove: (destination: string) => void;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  selectedRows: RowModel<FileInfoDto>;
}) => {
  const api = useApi();
  const { folderId } = useParams<{ folderId?: string }>();
  const [folders, setFolders] = useState<{ label: string; value: string }[]>(
    []
  );
  const [destination, setDestination] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const selectedRowsIncludesFolder = selectedRows.rows.some(
    (e) => e.original.mimeType === "application/vnd.google-apps.folder"
  );
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchFileList = async () => {
      const fileList: FileInfoDto[] = await getUserFolders(
        api,
        user?.role === "client"
          ? user?.username ?? ""
          : window.location.pathname.split("/")[2]
      );
      console.log("fileList", fileList);
      const filteredFileList = fileList
        .filter(
          (e) =>
            e.mimeType === "application/vnd.google-apps.folder" &&
            e.id !== folderId
        )
        .map((e) => ({ label: e.name || "", value: e.id || "" }));
      setFolders(filteredFileList);
    };

    fetchFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomModal opened={opened} setOpened={setOpened} loading={loading}>
      <Title
        order={3}
        mt={10}
        mb={10}
        style={{ textAlign: "center", fontSize: 20 }}
        color="#333333"
      >
        {selectedRowsIncludesFolder ? (
          <>
            Vous ne pouvez pas <br /> déplacer de dossiers
          </>
        ) : (
          "Déplacer ces fichiers dans"
        )}
      </Title>
      <Select
        mt={25}
        placeholder="Dossier"
        data={folders}
        withinPortal
        value={destination?.value || null}
        onChange={(selectedValue) => {
          const selectedOption = folders.find(
            (option) => option.value === selectedValue
          );
          setDestination(selectedOption || null);
        }}
      />
      <div style={{ marginTop: "2em", display: "flex" }}>
        <Button
          style={{ marginRight: "1em", flex: 1, color: "#333333" }}
          onClick={() => setOpened(false)}
          variant="default"
        >
          Annuler
        </Button>
        <Button
          style={{ flex: 1 }}
          color="primary"
          onClick={() => handleMove(destination?.value || "")}
          disabled={selectedRowsIncludesFolder || !destination}
        >
          Déplacer
        </Button>
      </div>
    </CustomModal>
  );
};
export default MoveModal;
