export enum MailjetTemplate {
  CUSTOMER_UPLOADED_INTERNAL = 4896349,
  CUSTOMER_UPLOADED_TO_CUSTOMER = 4896899,
  FILE_IN_PRODUCTION = 4904479,
  REGISTER_NEW_USER = 4904576,
  RESET_PASSWORD = 4906174,
  REDACTOR_COMMENT = 6114786,
  TASK_MODIFICATION = 6124286,
}

export class MailerDto {
  to?: string | string[];
  template?: MailjetTemplate;
  variables?: Record<string, string>;
  options?: Record<string, any> = {};
  username?: string;
}
