// useApi.ts
import { useContext } from "react";
import { UserContext } from "./UserContext";
import axios from "axios";

let baseUrl;

if (process.env.REACT_APP_IS_STAGING === "true") {
  baseUrl = "https://api-staging.compte-rendu.fr/";
} else if (process.env.NODE_ENV === "production") {
  baseUrl = "https://api-clients.compte-rendu.fr/";
} else {
  baseUrl = "http://localhost:8000";
}

export const backendUrl = baseUrl;

const api = axios.create({
  baseURL: baseUrl, // Replace with your backend API's base URL
  withCredentials: true, // Send cookies with requests
});

export function useApi() {
  const { setUser, user } = useContext(UserContext);

  api.interceptors.request.use((config) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user && user.accessToken) {
      config.headers["Authorization"] = `Bearer ${user.accessToken}`;
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401 && user) {
        // If a request is unauthorized, clear the user's local authentication data
        localStorage.removeItem("user");
        setUser(null);
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );

  return api;
}
