import { flexRender, HeaderGroup, RowData, Table } from "@tanstack/react-table";
import React from "react";
import Filter from "./Filter";
import {
  IconArrowDown,
  IconArrowsUpDown,
  IconArrowUp,
} from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import { LoadingOverlay, Title } from "@mantine/core";

function getTableHeaderGroups<T extends RowData>(
  table: Table<T>
): [HeaderGroup<T>[], HeaderGroup<T>[]] {
  return [table.getHeaderGroups(), table.getFooterGroups()];
}

type Props<T extends RowData> = {
  table: Table<T>;
  loading: boolean;
};

export function CustomTable<T extends RowData>({ table, loading }: Props<T>) {
  const [headerGroups] = getTableHeaderGroups(table);
  const location = useLocation();

  return (
    <table className="tanstack">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className="relative"
                key={header.id}
                style={{
                  width: header.getSize(),
                }}
                colSpan={header.colSpan}
              >
                {header.isPlaceholder ? null : (
                  <>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}{" "}
                      {header.column.getCanSort() ? (
                        <div
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer select-none"
                          style={{ marginLeft: 4, marginTop: 2 }}
                        >
                          {{
                            asc: <IconArrowUp size={14} />,
                            desc: <IconArrowDown size={14} />,
                          }[header.column.getIsSorted() as string] ?? (
                            <IconArrowsUpDown size={14} />
                          )}
                        </div>
                      ) : null}
                    </div>
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                  </>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td
              colSpan={location.pathname === "/users" ? 9 : 6}
              style={{
                textAlign: "center",
                padding: "40px 0",
                position: "relative",
              }}
            >
              <Title>{"\u00A0"}</Title>
              <LoadingOverlay
                visible={true}
                style={{
                  borderBottomLeftRadius: 12,
                  borderBottomRightRadius: 12,
                }}
              />
            </td>
          </tr>
        ) : (
          <>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {table.getRowModel().rows.length === 0 && (
              <tr>
                <td
                  colSpan={location.pathname === "/users" ? 9 : 6}
                  style={{
                    textAlign: "center",
                    padding: "40px 0",
                  }}
                >
                  <Title>
                    {location.pathname === "/users"
                      ? "Pas de résultats"
                      : "Dossier vide"}
                  </Title>
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
    </table>
  );
}

export default CustomTable;
