import {
  ColumnDef,
  FilterFn,
  // SortingFn,
  // sortingFns,
} from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useState } from "react";

export type TableMeta = {
  updateData: (rowIndex: number, columnId: string, value: unknown) => void;
};

// If at some point there is too much lag with fhis filtering, we can only analyse the relevant columns
// export const fuzzyFilter: FilterFn<any> = (
//   row,
//   columnId,
//   filterValue,
//   addMeta
// ) => {
//   const search = filterValue.toLowerCase();
//   // Convert to String
//   const value = String(row.getValue<string>(columnId));
//   return value?.toLowerCase().includes(search);
// };

export const fuzzyFilter: FilterFn<any> = (
  row,
  columnId,
  filterValue,
  addMeta
) => {
  const search = filterValue.toLowerCase();

  return row.getAllCells().some((cell) => {
    const column = cell.column;
    const value = cell.getValue();

    // Special handling for the comment column
    if (column.id === "comment") {
      const commentValue = [
        row.original.comment,
        row.original.commentRedaction,
        row.original.commentRedactor,
      ]
        .filter(Boolean)
        .join(" / ")
        .toLowerCase();
      return commentValue.includes(search);
    }

    // For other columns, use the existing logic
    if (typeof value === "number") {
      return value.toString().includes(search);
    }
    if (typeof value === "string") {
      return value.toLowerCase().includes(search);
    }
    if (typeof value === "object" && value !== null) {
      return JSON.stringify(value).toLowerCase().includes(search);
    }
    return false;
  });
};

export const getTableMeta = (
  setData: React.Dispatch<React.SetStateAction<any[]>>,
  skipAutoResetPageIndex: () => void
) =>
  ({
    updateData: (rowIndex, columnId, value) => {
      // Skip age index reset until after next rerender
      skipAutoResetPageIndex();
      setData((old) =>
        old.map((row, index) => {
          if (index !== rowIndex) return row;

          return {
            ...old[rowIndex]!,
            [columnId]: value,
          };
        })
      );
    },
  } as TableMeta);

// Give our default column cell renderer editing superpowers!
export const defaultColumn: Partial<ColumnDef<any>> = {
  cell: function Cell({ getValue, row: { index }, column: { id }, table }) {
    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      (table.options.meta as TableMeta).updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        value={value as string}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  },
};

export const displayDate = (date: string) =>
  date === "0000-00-00" || !date ? "Nd" : moment(date).format("DD/MM/YYYY");

export const updateDate = (date: Date) =>
  (date as any as string) === "0000-00-00" ? undefined : new Date(date);
