export class FileInfoDto {
  id?: string;
  name?: string;
  createdTime?: Date;
  modifiedTime?: Date;
  mimeType?: string;
  size?: number;

  userId?: number;
  state?: State;
  owner?: string;
}

export enum State {
  drop = 'Déposé',
  processing = 'En traitement',
  finished = 'Terminé',
}
