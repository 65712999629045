import CustomModal from "./CustomModal";
import { Button, Text, Title } from "@mantine/core";
import GlowingIcon from "./GlowingIcon";

const DeleteModal = ({
  handleDelete,
  opened,
  setOpened,
  loading,
  profile = false,
}: {
  handleDelete: () => void;
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  profile?: boolean;
}) => {
  return (
    <CustomModal opened={opened} setOpened={setOpened} loading={loading}>
      <div style={{ display: "flex" }}>
        <GlowingIcon icon="trash" />
        <div>
          <Title
            order={3}
            mt={10}
            mb={10}
            style={{ fontSize: 20 }}
            color="#333333"
          >
            {profile ? "Supprimer l'utilisateur" : "Supprimer la sélection"}
          </Title>
          <Text style={{ fontSize: 14 }} mr={15} color="#656565">
            Êtes-vous sûr de vouloir supprimer{" "}
            {profile ? "l'utilisateur" : "la sélection"} ? Cette action est
            irréversible.
          </Text>
        </div>
      </div>
      <div
        style={{
          marginTop: "2em",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{ marginRight: "1em", color: "#333333" }}
          onClick={() => setOpened(false)}
          variant="default"
        >
          Annuler
        </Button>
        <Button color="red" onClick={handleDelete}>
          Supprimer
        </Button>
      </div>
    </CustomModal>
  );
};
export default DeleteModal;
