import React from "react";
import { capitalizeFirstLetter } from "../utils";
import { Badge } from "@mantine/core";

const DayCellTitle = ({
  day,
  formattedToday,
  isMemberCalendar,
}: {
  day: moment.Moment;
  formattedToday: string;
  isMemberCalendar?: boolean;
}) => {
  return (
    <div
      style={{
        width: isMemberCalendar ? 200 : 100,
        height: isMemberCalendar ? undefined : 200,
        textAlign: "center",
        borderBottom: isMemberCalendar ? "1px solid #EAECF0" : undefined,
        borderRight: isMemberCalendar ? "1px solid #EAECF0" : undefined,
        borderLeft: isMemberCalendar ? undefined : "1px solid #EAECF0",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: 10,
        padding: isMemberCalendar ? 10 : undefined,
        flexShrink: isMemberCalendar ? undefined : 0,
      }}
    >
      <div>{capitalizeFirstLetter(day.format("ddd"))}</div>
      <div>
        {day.format("YYYY-MM-DD") === formattedToday ? (
          <Badge
            style={{
              color: "white",
              height: 48,
              width: 48,
              fontSize: 18,
              overflow: "hidden",
            }}
          >
            {day.format("DD")}
          </Badge>
        ) : (
          <div style={{ height: 48, width: 48, fontSize: 24 }}>
            {day.format("DD")}
          </div>
        )}
      </div>
    </div>
  );
};

export default DayCellTitle;
