import { createStyles } from "@mantine/core";
import { IconFile } from "@tabler/icons-react";

const IconType = ({
  type,
  ...rest
}: { type: string | undefined } & React.HTMLProps<HTMLInputElement>) => {
  const { classes } = useStyles();

  if (!type) return null;
  let [category, subcategoty] = type?.split("/");
  if (subcategoty === "pdf") category = "pdf";
  if (subcategoty === "vnd.google-apps.folder") category = subcategoty;

  switch (category) {
    case "pdf":
      return (
        <span {...rest} className={classes.box}>
          <IconFile size={20} className={classes.icon} />
        </span>
      );
    case "vnd.google-apps.folder":
      return (
        <span {...rest} className={classes.boxFolder}>
          <span className={classes.icon}>{FolderIcon}</span>
        </span>
      );
    case "video":
      return (
        <span {...rest} className={classes.box}>
          <span className={classes.icon}>{VideoIcon}</span>
        </span>
      );
    case "image":
      return (
        <span {...rest} className={classes.box}>
          <span className={classes.icon}>{ImageIcon}</span>
        </span>
      );
    case "audio":
      return (
        <span {...rest} className={classes.box}>
          <span className={classes.icon}>{AudioIcon}</span>
        </span>
      );
    default:
      return (
        <span {...rest} className={classes.box}>
          <IconFile size={20} className={classes.icon} />
        </span>
      );
  }
};
export default IconType;

const useStyles = createStyles((theme) => ({
  box: {
    width: 40,
    height: 40,
    position: "relative",
  },
  boxFolder: {
    width: 40,
    height: 40,
    position: "relative",
    backgroundColor: "#2E6DA4",
    borderRadius: 8,
  },
  icon: {
    position: "absolute",
    left: 10,
    top: 10,
    color: "#2E6DA4",
  },
}));

export const FolderIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.833314 5.13475C0.833299 4.69547 0.833287 4.31648 0.85879 4.00434C0.885707 3.67489 0.945137 3.34721 1.1058 3.03189C1.34548 2.56149 1.72793 2.17903 2.19834 1.93935C2.51366 1.77869 2.84134 1.71926 3.17079 1.69234C3.48293 1.66684 3.8619 1.66685 4.30119 1.66687L7.6222 1.66678C8.11683 1.66612 8.55181 1.66555 8.95177 1.8035C9.30208 1.92433 9.62115 2.12153 9.88589 2.38082C10.1881 2.67686 10.3822 3.06618 10.6028 3.50888L11.3483 5.00002H14.3677C15.0385 5.00001 15.5922 5 16.0432 5.03685C16.5116 5.07512 16.9422 5.15726 17.3466 5.36333C17.9738 5.68291 18.4838 6.19285 18.8033 6.82005C19.0094 7.2245 19.0915 7.65509 19.1298 8.1235C19.1667 8.57449 19.1667 9.12812 19.1666 9.79892V13.5345C19.1667 14.2053 19.1667 14.7589 19.1298 15.2099C19.0915 15.6783 19.0094 16.1089 18.8033 16.5133C18.4838 17.1405 17.9738 17.6505 17.3466 17.97C16.9422 18.1761 16.5116 18.2583 16.0432 18.2965C15.5922 18.3334 15.0385 18.3334 14.3677 18.3334H5.63225C4.96144 18.3334 4.40779 18.3334 3.95679 18.2965C3.48838 18.2583 3.05779 18.1761 2.65335 17.97C2.02614 17.6505 1.5162 17.1405 1.19663 16.5133C0.99055 16.1089 0.908413 15.6783 0.870142 15.2099C0.833295 14.7589 0.833303 14.2053 0.833314 13.5345V5.13475ZM7.51856 3.33353C8.17723 3.33353 8.3078 3.34441 8.40832 3.37908C8.52509 3.41936 8.63145 3.48509 8.71969 3.57152C8.79566 3.64592 8.86377 3.75784 9.15834 4.34697L9.48487 5.00002L2.50004 5.00002C2.50041 4.61253 2.50296 4.34765 2.51992 4.14006C2.53842 3.91368 2.56982 3.82974 2.59081 3.78854C2.6707 3.63174 2.79819 3.50426 2.95499 3.42436C2.99619 3.40337 3.08013 3.37197 3.30651 3.35347C3.54263 3.33418 3.85285 3.33353 4.33332 3.33353H7.51856Z"
      fill="white"
    />
  </svg>
);

export const VideoIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1712_11826)">
      <path
        d="M10 18.3333V1.66666M5.83335 18.3333V14.1667M5.83335 5.83332V1.66666M14.1667 18.3333V14.1667M14.1667 5.83332V1.66666M1.66669 5.83332H18.3334M1.66669 14.1667H18.3334M18.3334 14.3333V5.66666C18.3334 4.26653 18.3334 3.56646 18.0609 3.03168C17.8212 2.56127 17.4387 2.17882 16.9683 1.93914C16.4336 1.66666 15.7335 1.66666 14.3334 1.66666L5.66669 1.66666C4.26656 1.66666 3.56649 1.66666 3.03171 1.93914C2.56131 2.17882 2.17885 2.56128 1.93917 3.03168C1.66669 3.56646 1.66669 4.26653 1.66669 5.66666L1.66669 14.3333C1.66669 15.7335 1.66669 16.4335 1.93917 16.9683C2.17885 17.4387 2.56131 17.8212 3.03171 18.0608C3.56649 18.3333 4.26656 18.3333 5.66669 18.3333H14.3334C15.7335 18.3333 16.4336 18.3333 16.9683 18.0608C17.4387 17.8212 17.8212 17.4387 18.0609 16.9683C18.3334 16.4335 18.3334 15.7335 18.3334 14.3333Z"
        stroke="#2E6DA4"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1712_11826">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ImageIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 17.5H5.77614C5.2713 17.5 5.01887 17.5 4.90199 17.4002C4.80056 17.3135 4.74674 17.1836 4.75721 17.0506C4.76927 16.8974 4.94776 16.7189 5.30474 16.3619L12.3905 9.27614C12.7205 8.94613 12.8855 8.78112 13.0758 8.7193C13.2432 8.66492 13.4235 8.66492 13.5908 8.7193C13.7811 8.78112 13.9461 8.94613 14.2761 9.27614L17.5 12.5V13.5M13.5 17.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5M13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5M8.75 7.08333C8.75 8.00381 8.00381 8.75 7.08333 8.75C6.16286 8.75 5.41667 8.00381 5.41667 7.08333C5.41667 6.16286 6.16286 5.41667 7.08333 5.41667C8.00381 5.41667 8.75 6.16286 8.75 7.08333Z"
      stroke="#2E6DA4"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const AudioIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 8.33333L2.5 11.6667M6.25 9.16667V10.8333M10 5V15M13.75 2.5V17.5M17.5 8.33333V11.6667"
      stroke="#2E6DA4"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconFolderPlus = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8333 5.83333L9.90371 3.9741C9.63616 3.439 9.50238 3.17144 9.3028 2.97597C9.12631 2.80311 8.91359 2.67164 8.68005 2.59109C8.41596 2.5 8.11683 2.5 7.51857 2.5H4.33332C3.3999 2.5 2.93319 2.5 2.57667 2.68166C2.26307 2.84144 2.0081 3.09641 1.84831 3.41002C1.66666 3.76654 1.66666 4.23325 1.66666 5.16667V5.83333M1.66666 5.83333H14.3333C15.7335 5.83333 16.4335 5.83333 16.9683 6.10582C17.4387 6.3455 17.8212 6.72795 18.0608 7.19836C18.3333 7.73314 18.3333 8.4332 18.3333 9.83333V13.5C18.3333 14.9001 18.3333 15.6002 18.0608 16.135C17.8212 16.6054 17.4387 16.9878 16.9683 17.2275C16.4335 17.5 15.7335 17.5 14.3333 17.5H5.66666C4.26653 17.5 3.56646 17.5 3.03168 17.2275C2.56128 16.9878 2.17882 16.6054 1.93914 16.135C1.66666 15.6002 1.66666 14.9001 1.66666 13.5V5.83333ZM9.99999 14.1667V9.16667M7.49999 11.6667H12.5"
      stroke="#2E6DA4"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconHome = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 14.1667H13.3333M9.18141 2.30333L3.52949 6.69927C3.15168 6.99312 2.96278 7.14005 2.82669 7.32405C2.70614 7.48704 2.61633 7.67065 2.56169 7.86588C2.5 8.08627 2.5 8.32558 2.5 8.80421V14.8333C2.5 15.7667 2.5 16.2335 2.68166 16.59C2.84144 16.9036 3.09641 17.1585 3.41002 17.3183C3.76654 17.5 4.23325 17.5 5.16667 17.5H14.8333C15.7668 17.5 16.2335 17.5 16.59 17.3183C16.9036 17.1585 17.1586 16.9036 17.3183 16.59C17.5 16.2335 17.5 15.7667 17.5 14.8333V8.80421C17.5 8.32558 17.5 8.08627 17.4383 7.86588C17.3837 7.67065 17.2939 7.48704 17.1733 7.32405C17.0372 7.14005 16.8483 6.99312 16.4705 6.69927L10.8186 2.30333C10.5258 2.07562 10.3794 1.96177 10.2178 1.918C10.0752 1.87938 9.92484 1.87938 9.78221 1.918C9.62057 1.96177 9.47418 2.07562 9.18141 2.30333Z"
      stroke="#656565"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconChevronRight = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 12L10 8L6 4"
      stroke="#D0D5DD"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconClose = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18M6 6L18 18"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconDownload = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5H2.5M15 9.16667L10 14.1667M10 14.1667L5 9.16667M10 14.1667V2.5"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconTrashRed = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
      stroke="#E74C3C"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconTrashGray = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconMoveSelected = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1712_19213)">
      <path
        d="M2.78166 5.83342C4.22253 3.34258 6.91563 1.66675 10.0001 1.66675C14.6025 1.66675 18.3335 5.39771 18.3335 10.0001C18.3335 14.6025 14.6025 18.3334 10.0001 18.3334C6.91563 18.3334 4.22253 16.6576 2.78166 14.1667M10.0001 13.3334L13.3334 10.0001M13.3334 10.0001L10.0001 6.66675M13.3334 10.0001H1.66675"
        stroke="#2E6DA4"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1712_19213">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const IconSearch = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 17.5L14.5834 14.5833M16.6667 9.58333C16.6667 13.4954 13.4954 16.6667 9.58333 16.6667C5.67132 16.6667 2.5 13.4954 2.5 9.58333C2.5 5.67132 5.67132 2.5 9.58333 2.5C13.4954 2.5 16.6667 5.67132 16.6667 9.58333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconUploadCloud = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66667 13.3333L10 10M10 10L13.3333 13.3333M10 10V17.5M16.6667 13.9524C17.6846 13.1117 18.3333 11.8399 18.3333 10.4167C18.3333 7.88536 16.2813 5.83333 13.75 5.83333C13.5679 5.83333 13.3976 5.73833 13.3051 5.58145C12.2184 3.73736 10.212 2.5 7.91667 2.5C4.46489 2.5 1.66667 5.29822 1.66667 8.75C1.66667 10.4718 2.36288 12.0309 3.48913 13.1613"
      stroke="#2E6DA4"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconWarningBlack = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99988 7.49995V10.8333M9.99988 14.1666H10.0082M8.84598 3.24305L1.9919 15.0819C1.61173 15.7386 1.42165 16.0669 1.44974 16.3364C1.47425 16.5714 1.59739 16.785 1.78852 16.924C2.00765 17.0833 2.38704 17.0833 3.14581 17.0833H16.8539C17.6127 17.0833 17.9921 17.0833 18.2112 16.924C18.4024 16.785 18.5255 16.5714 18.55 16.3364C18.5781 16.0669 18.388 15.7386 18.0078 15.0819L11.1538 3.24305C10.775 2.58875 10.5856 2.26159 10.3385 2.15172C10.1229 2.05587 9.87684 2.05587 9.66129 2.15172C9.41419 2.26159 9.22478 2.58875 8.84598 3.24305Z"
      stroke="#333333"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconDotsVertical = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 10C10.8334 9.53977 10.4603 9.16668 10.0001 9.16668C9.53984 9.16668 9.16675 9.53977 9.16675 10C9.16675 10.4602 9.53984 10.8333 10.0001 10.8333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 5.00001C10.4603 5.00001 10.8334 4.62691 10.8334 4.16668C10.8334 3.70644 10.4603 3.33334 10.0001 3.33334C9.53984 3.33334 9.16675 3.70644 9.16675 4.16668C9.16675 4.62691 9.53984 5.00001 10.0001 5.00001Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 16.6667C10.4603 16.6667 10.8334 16.2936 10.8334 15.8333C10.8334 15.3731 10.4603 15 10.0001 15C9.53984 15 9.16675 15.3731 9.16675 15.8333C9.16675 16.2936 9.53984 16.6667 10.0001 16.6667Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconLifeBoat = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.13626 9.13628L4.92893 4.92896M4.92893 19.0711L9.16797 14.8321M14.8611 14.8638L19.0684 19.0711M19.0684 4.92896L14.8287 9.16862M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
      stroke="#2E6DA4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconUserPlus = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99935 12.9167H6.24935C5.08638 12.9167 4.50489 12.9167 4.03173 13.0602C2.96639 13.3834 2.13272 14.217 1.80955 15.2824C1.66602 15.7555 1.66602 16.337 1.66602 17.5M15.8327 17.5V12.5M13.3327 15H18.3327M12.0827 6.25C12.0827 8.32107 10.4038 10 8.33268 10C6.26161 10 4.58268 8.32107 4.58268 6.25C4.58268 4.17893 6.26161 2.5 8.33268 2.5C10.4038 2.5 12.0827 4.17893 12.0827 6.25Z"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const IconCheckBoxChecked = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#F9FAFB" />
    <path
      d="M12 5L6.5 10.5L4 8"
      stroke="#656565"
      strokeWidth="1.6666"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#656565" />
  </svg>
);

export const IconCheckBoxUnchecked = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#F9FAFB" />
    <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#656565" />
  </svg>
);
