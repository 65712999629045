import React, { useEffect, useState, useRef } from "react";
import { HeaderAction } from "../components/HeaderAction";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Menu,
  Select,
  Title,
} from "@mantine/core";
import { TaskDto, TaskType, UserDto } from "../dto/user";
import { useApi } from "../useApi";
import { getTasks, getUsersActivatedMembers } from "../apiRoutes";
import moment, { Moment } from "moment";
import Legend from "../components/calendar/Legend";
import DayCell from "../components/DayCell";
import { capitalizeFirstLetter, generateDays } from "../utils";
import { IconChevronLeft, IconPencil, IconPlus } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import TaskForm from "../components/TaskForm";
import { IconChevronRight } from "@tabler/icons-react";
import DayCellTitle from "../components/DayCellTitle";

const UserHeader = ({
  user,
}: {
  user: {
    label: string;
    value: string;
    places: string;
  };
}) => {
  return (
    <div
      style={{
        width: 100,
        minWidth: 100,
        textAlign: "center",
        padding: "15px 0",
        position: "sticky",
        top: 0,
        background: "white",
        fontWeight: 500,
        zIndex: 2,
        minHeight: 82,
        whiteSpace: "nowrap",
        overflow: "visible",
      }}
    >
      <div>{user.label}</div>
      <div>{user.places ? `(${user.places})` : ""}</div>
    </div>
  );
};

const getNextWeekday = (date: Moment): Moment => {
  let nextDate = date.clone();
  while (nextDate.day() === 0 || nextDate.day() === 6) {
    nextDate = nextDate.add(1, "day");
  }
  return nextDate;
};

const Planning = () => {
  const [tasks, setTasks] = useState<TaskDto[]>([]);
  const [days, setDays] = useState<Moment[]>([]);
  const [redactors, setRedactors] = useState<
    { label: string; value: string; places: string }[]
  >([]);
  const [selectedRedactor, setSelectedRedactor] = useState<string | null>(null);
  const [, setLoading] = useState<boolean>(true);
  const api = useApi();
  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];
  const navigation = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [refreshCounter, setRefreshCounter] = React.useState(0);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const dateRefs = useRef<{ [key: string]: HTMLTableRowElement }>({});
  const [isScrollEnabled, setIsScrollEnabled] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsScrollEnabled(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isScrollEnabled) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "visible"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "visible"; // Re-enable scrolling on cleanup
    };
  }, [isScrollEnabled]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      const response: UserDto[] = await getUsersActivatedMembers(api);
      console.log("activated members", response);
      const redactorsRaw = response.map((user) => ({
        label: user?.code ?? "",
        places: user?.places ?? "",
        value: user.id?.toString() ?? "",
      }));
      setRedactors(redactorsRaw);
      setLoading(false);
    };
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    const fetchTasks = async () => {
      const { data }: { data: TaskDto[] } = await getTasks(api);
      console.log("tasks", data);
      setLoading(false);
      setTasks(data);
    };
    fetchTasks();
  }, [api, refreshCounter]);

  // @ts-ignore
  const filterTasksForDay = (day) => {
    const dayStr = day.format("YYYY-MM-DD");
    return tasks.filter((task) => {
      // @ts-ignore
      const startDateStr = task?.startDate?.split("T")[0];
      // @ts-ignore
      const endDateStr = task?.endDate?.split("T")[0] || startDateStr;
      return startDateStr <= dayStr && dayStr <= endDateStr;
    });
  };

  useEffect(() => {
    setDays(generateDays(currentMonth));
  }, [currentMonth]);

  useEffect(() => {
    let currentDay = moment(today);
    if (currentDay.day() === 0 || currentDay.day() === 6) {
      currentDay = getNextWeekday(currentDay);
    }
    const currentDayKey = currentDay.format("YYYY-MM-DD");
    if (dateRefs.current[currentDayKey]) {
      dateRefs.current[currentDayKey].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  const filterTasksForRedactor = (
    tasksForDay: TaskDto[],
    redactorId: number
  ) => {
    return tasksForDay.filter((task) => task.redactorId === redactorId);
  };

  return (
    <Box bg={"#F9FAFB"} pb={1}>
      <HeaderAction planning />
      <TaskForm
        opened={opened}
        open={open}
        close={close}
        isPlanning={true}
        isRecordings={false}
        api={api}
        setRefreshCounter={setRefreshCounter}
        // users={users}
        redactors={redactors}
        // setIsPlanning={setIsPlanning}
        tasks={tasks}
      />
      <Box
        style={{
          backgroundColor: "white",
          border: "1px solid #EAECF0",
          borderTopRightRadius: 12,
          borderTopLeftRadius: 12,
        }}
        mr={16}
        ml={16}
        mt={16}
        p={24}
      >
        <Flex justify="space-between">
          <Flex gap={10}>
            <ActionIcon
              variant="default"
              onClick={() =>
                setCurrentMonth(currentMonth.clone().subtract(1, "months"))
              }
            >
              <IconChevronLeft size="1rem" />
            </ActionIcon>
            <Title mb={5} mt={2}>
              Planning {capitalizeFirstLetter(currentMonth.format("MMMM YYYY"))}
            </Title>
            <ActionIcon
              variant="default"
              onClick={() =>
                setCurrentMonth(currentMonth.clone().add(1, "months"))
              }
            >
              <IconChevronRight size="1rem" />
            </ActionIcon>
          </Flex>
          <Flex gap={20}>
            <Select
              data={redactors}
              placeholder="Rédacteurs"
              onChange={(value) => {
                console.log(value);
                setSelectedRedactor(value);
              }}
              clearable
              searchable
            />
            <Menu trigger="hover" openDelay={100} closeDelay={400}>
              <Menu.Target>
                <Button
                  leftIcon={<IconPlus size={18} />}
                  style={{ height: 36 }}
                >
                  Créer
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => {
                    const createBreak = new CustomEvent("planTask", {
                      detail: { taskId: 0, type: TaskType.break },
                    });
                    window.dispatchEvent(createBreak);
                  }}
                >
                  Absence
                </Menu.Item>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => {
                    const createBreak = new CustomEvent("planTask", {
                      detail: { taskId: 0, type: TaskType.otherFlexible },
                    });
                    window.dispatchEvent(createBreak);
                  }}
                >
                  Autre Flexible
                </Menu.Item>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => {
                    const createBreak = new CustomEvent("planTask", {
                      detail: { taskId: 0, type: TaskType.otherFixed },
                    });
                    window.dispatchEvent(createBreak);
                  }}
                >
                  Autre Fixé
                </Menu.Item>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => navigation("/reunions")}
                >
                  Enregistrement
                </Menu.Item>
                <Menu.Item
                  icon={<IconPencil size={14} />}
                  onClick={() => navigation("/enregistrements")}
                >
                  Réunion
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Flex>
        <Legend />
      </Box>
      <div
        style={{
          marginRight: 16,
          marginLeft: 16,
          position: "relative",
          marginBottom: 16,
        }}
      >
        {!selectedRedactor && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: 102,
              height: 84,
              backgroundColor: "white",
              zIndex: 3,
              border: "1px solid #EAECF0",
            }}
          />
        )}
        <div
          style={{
            borderCollapse: "collapse",
            width: "100%",
            overflow: "auto",
            height: "calc(100vh - 223px)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                {[
                  { label: "", value: "", places: "" },
                  ...redactors.filter(
                    (u) => u.value === selectedRedactor || !selectedRedactor
                  ),
                ].map((user, index) => (
                  <th
                    key={index}
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 2,
                      backgroundColor: "white",
                      minWidth: 100,
                      padding: 0,
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid #EAECF0",
                      }}
                    >
                      <UserHeader key={index} user={user} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {days.map((day, rowIndex) => {
                const dayStr = day.format("YYYY-MM-DD");
                const tasksForDay = filterTasksForDay(day);
                return (
                  <tr
                    key={rowIndex}
                    style={{ position: "relative" }}
                    ref={(el) => {
                      if (el && isScrollEnabled) {
                        dateRefs.current[dayStr] = el;
                        if (dayStr === moment(today).format("YYYY-MM-DD")) {
                          const invisibleAnchor = document.createElement("div");
                          invisibleAnchor.style.position = "absolute";
                          invisibleAnchor.style.top = "-82px";
                          invisibleAnchor.style.width = "1px";
                          invisibleAnchor.style.height = "1px";
                          el.firstElementChild?.appendChild(invisibleAnchor);
                          invisibleAnchor.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                          });
                        }
                      }
                    }}
                  >
                    {[
                      { label: "", value: "", places: "" },
                      ...redactors.filter(
                        (u) => u.value === selectedRedactor || !selectedRedactor
                      ),
                    ].map((user, colIndex) => (
                      <td
                        key={colIndex}
                        style={{
                          position: colIndex === 0 ? "sticky" : undefined,
                          left: colIndex === 0 ? 0 : undefined,
                          zIndex: colIndex === 0 ? 1 : undefined,
                          backgroundColor: "white",
                          minWidth: 100,
                          padding: 0,
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid #EAECF0",
                            height: "100%",
                          }}
                        >
                          {colIndex === 0 ? (
                            <DayCellTitle
                              day={day}
                              formattedToday={formattedToday}
                              key={colIndex}
                            />
                          ) : (
                            <DayCell
                              day={day}
                              tasks={filterTasksForRedactor(
                                tasksForDay,
                                +user.value
                              )}
                            />
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Box>
  );
};

export default Planning;
