import { Button, TextInput, Title } from "@mantine/core";
import { useState } from "react";
import { IconFolderPlus } from "./IconType";
import CustomModal from "./secondary/CustomModal";
import { useParams } from "react-router-dom";
import { useApi } from "../useApi";
import { postFolder } from "../apiRoutes";
import { UserDto } from "../dto/user";

const NewFolder = ({
  refresh,
  user,
}: {
  refresh: () => Promise<void>;
  user: UserDto | null;
}) => {
  const [opened, setOpened] = useState(false);
  const [folderName, setFolderName] = useState("");
  const { folderId, username } = useParams<{
    folderId?: string;
    username?: string;
  }>();
  const api = useApi();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (folderName === "") {
      return null;
    }
    setLoading(true);
    try {
      const folder = await postFolder(api, folderName, folderId, username);
      console.log("folder", folder);
      setTimeout(async () => {
        await refresh();
        setOpened(false);
        setFolderName("");
      }, 500);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (
    (window.location.pathname === "/" && user?.role !== "client") ||
    (user?.role === "client" && folderId?.length) ||
    (user?.role !== "client" &&
      window.location.pathname.split("/").length === 5)
  )
    return null;

  return (
    <>
      <Button
        variant="default"
        leftIcon={IconFolderPlus}
        styles={{
          inner: { paddingLeft: 4 },
        }}
        onClick={() => setOpened(true)}
      >
        Créer un dossier
      </Button>
      <CustomModal opened={opened} setOpened={setOpened} loading={loading}>
        <Title
          order={3}
          mt={10}
          mb={10}
          style={{ textAlign: "center", fontSize: 20 }}
          color="#333333"
        >
          Créer un dossier
        </Title>
        <TextInput
          label="Nom*"
          placeholder="Entrez un nom"
          value={folderName}
          onChange={(event) => setFolderName(event.currentTarget.value)}
          styles={{ input: { height: 44 } }}
        />
        <div style={{ marginTop: "2em", display: "flex" }}>
          <Button
            style={{ marginRight: "1em", flex: 1, color: "#333333" }}
            onClick={() => setOpened(false)}
            variant="default"
          >
            Annuler
          </Button>
          <Button style={{ flex: 1 }} color="primary" onClick={handleSubmit}>
            Créer
          </Button>
        </div>
      </CustomModal>
    </>
  );
};
export default NewFolder;
