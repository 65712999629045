export const processingLevel = [
  {
    value: "TRS",
    label: "TRS - Transcription mot à mot – 1 heure",
    maxTimePerDay: 1,
  },
  {
    value: "CRE",
    label: "CRE - Compte rendu exhaustif – 1h",
    maxTimePerDay: 1,
  },
  {
    value: "CRO",
    label: "CRO - Compte rendu optimisé – 1h30",
    maxTimePerDay: 1.5,
  },
  {
    value: "CRS",
    label: "CRS - Compte rendu synthétique – 2h15",
    maxTimePerDay: 2.25,
  },
  { value: "SYD", label: "SYD - Synthèse détaillée – 3h", maxTimePerDay: 3 },
  {
    value: "SYBD",
    label: "SYBD - Synthèse brève-détaillée – 3h30",
    maxTimePerDay: 3.5,
  },
  { value: "SYB", label: "SYB - Synthèse brève – 4h", maxTimePerDay: 4 },
  {
    value: "RD",
    label: "RD - Relevé de décisions – 1/3 de journée",
    maxTimePerDay: 7 / 3,
    fixedDuration: 2.5,
  },
];
