import { Box, Flex, Text } from "@mantine/core";
import React from "react";

const Legend = () => {
  return (
    <Flex>
      {[
        {
          text: "Enregistrement",
          borderColor: "#53B5D8",
          backgroundColor: "#C8E7F7",
        },
        {
          text: "Réunion",
          borderColor: "#60B286",
          backgroundColor: "#C2EEBA",
        },
        {
          text: "Rédaction réunion",
          borderColor: "#fff70e",
          backgroundColor: "#fffeeb",
        },
        {
          text: "Autre",
          borderColor: "#8276D1",
          backgroundColor: "#DBD6FA",
        },
        {
          text: "Absence",
          borderColor: "#CACACA",
          backgroundColor: "#F9FAFB",
        },
      ].map((elem, index) => (
        <Flex mr={24} key={index}>
          <Box
            style={{
              width: 20,
              height: 20,
              marginRight: 10,
              borderRadius: 32,
              marginTop: 2,
              backgroundColor: elem.backgroundColor,
              border: `1px solid ${elem.borderColor}`,
            }}
          />
          <Text fw={400} style={{ color: "#333333" }}>
            {elem.text}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Legend;
