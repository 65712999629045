import React, { useContext, useEffect } from "react";
import { HeaderAction } from "../components/HeaderAction";
import UserForm from "../components/UserForm";
import { useNavigate, useParams } from "react-router-dom";
import { IconChevronRight, IconHome } from "../components/IconType";
import HomePage from "./HomePage";
import { useApi } from "../useApi";
import { getUser } from "../apiRoutes";
import { UserDto } from "../dto/user";
import { UserContext } from "../UserContext";

const UserProfile = () => {
  const { user: originalUser } = useContext(UserContext);
  const { username } = useParams<{ username?: string }>();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState<"file" | "info">(
    originalUser?.isAdminPlanning ? "info" : "file"
  );
  const api = useApi();
  const [user, setUser] = React.useState<UserDto | undefined>(undefined);

  useEffect(() => {
    const refreshUser = async () => {
      if (!api || !username) return;
      try {
        const userRet = await getUser(api, username);
        setUser(userRet);
      } catch (e) {
        console.log(e);
      }
    };
    refreshUser();
  }, [username, api]);

  return (
    <>
      <HeaderAction planning={originalUser?.isAdminPlanning} />
      <div style={{ padding: 32, backgroundColor: "#FCFCFD" }}>
        <div
          style={{
            gap: 15,
            display: "flex",
            verticalAlign: "",
            marginBottom: 15,
          }}
        >
          <div onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
            {IconHome}
          </div>
          <div>{IconChevronRight}</div>
          <div style={{ fontSize: 14, color: "#656565" }}>Profil</div>
          <div>{IconChevronRight}</div>
          <div
            style={{ fontSize: 14, color: "#656565", cursor: "pointer" }}
            onClick={() => navigate(`/users/${username}`)}
          >
            {username}
          </div>
        </div>
        <h1
          style={{
            margin: 0,
            marginTop: -10,
            fontSize: 40,
            fontWeight: 500,
          }}
        >
          Espace de {username}
        </h1>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #EAECF0",
            marginTop: 20,
            marginBottom: 32,
          }}
        />
        <div
          style={{
            gap: 35,
            display: "flex",
            fontSize: 16,
          }}
        >
          <p
            style={{
              cursor: "pointer",
              borderBottom: tab === "file" ? "2px solid black" : "none",
              paddingBottom: tab === "file" ? "13px" : "15px",
              fontWeight: tab === "file" ? 700 : 500,
              color: tab === "file" ? "" : "#656565",
              marginBottom: -1.5,
            }}
            onClick={() => setTab("file")}
          >
            Fichiers
          </p>
          <p
            style={{
              cursor: "pointer",
              borderBottom: tab === "info" ? "2px solid black" : "none",
              paddingBottom: tab === "info" ? "13px" : "15px",
              fontWeight: tab === "info" ? 700 : 500,
              color: tab === "info" ? "" : "#656565",
              marginBottom: -1.5,
            }}
            onClick={() => setTab("info")}
          >
            Informations
          </p>
        </div>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #656565",
            marginBottom: 32,
          }}
        />
        {tab === "file" ? (
          <HomePage profile userProfile={user} />
        ) : (
          <div style={{ display: "flex" }}>
            <p
              style={{
                fontSize: 18,
                fontWeight: 600,
                maxWidth: 280,
                margin: 0,
              }}
            >
              Identifiants de l'utilisateur
            </p>
            <UserForm profile user={user} />
          </div>
        )}
      </div>
    </>
  );
};

export default UserProfile;
