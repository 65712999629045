export class UserDto {
  id?: number;
  activated?: boolean;
  email?: string;
  secoundEmail?: string;
  username?: string;
  code?: string;
  phoneNumber?: string;
  comment?: string;
  processingLevel?: string;
  orderNumber?: number;
  averageMeetingDuration?: number;
  password?: string;
  role?: UserRole;
  isAdminPlanning?: boolean;
  folderId?: string;
  createdAt?: Date;
  updatedAt?: Date;
  lastLogin?: Date;
  edit?: boolean;
  places?: string;
  nextAvailability?: Date; // only for redactors in the plannification

  customerTasks?: TaskDto[];
  redactorTasks?: TaskDto[];
}

export class TaskDto {
  id?: number;
  type?: TaskType;
  state?: TaskState;
  date?: Date;
  meetingDate?: string;
  format?: string;
  duration?: string;
  timeAtTheCustomer?: string;
  instructions?: string;
  comment?: string;
  commentRedaction?: string;
  commentRedactor?: string;
  place?: string;
  startDate?: Date;
  startTime?: string; // only for frontend
  endDate?: Date;
  endTime?: string; // only for frontend
  slotDuration?: string;
  deliveryDate?: Date;

  customer?: UserDto;
  customerId?: number | string;
  redactor?: UserDto;
  redactorId?: number | string;
}

export enum TaskType {
  meeting = "meeting",
  recordingWriting = "recordingWriting",
  meetingWriting = "meetingWriting",
  otherFlexible = "otherFlexible",
  otherFixed = "otherFixed",
  break = "break",
}

export enum TaskState {
  documentsReceived = "documentsReceived",
  readyToStart = "readyToStart",
  validatedByRedactor = "validatedByRedactor",
  delivered = "delivered",
  billed = "billed",
}

export enum UserRole {
  client = "client",
  administrateur = "administrateur",
  membre = "membre",
}

export class LoginUserDto {
  readonly username?: string;
  readonly password?: string;
}

export class ChangePasswordDto {
  readonly currentPassword?: string;
  readonly newPassword?: string;
  readonly token?: string;
}
