import React from "react";
import { HeaderAction } from "../components/HeaderAction";
import { Box, Title } from "@mantine/core";
import { UserDto } from "../dto/user";
import { useApi } from "../useApi";
import { getUsers } from "../apiRoutes";
import { ColumnDef, sortingFns } from "@tanstack/react-table";
import TableInit from "../components/table/TableInit";
import { IconArchive, IconCheck } from "@tabler/icons-react";

const columnsCustomers: ColumnDef<UserDto>[] = [
  {
    accessorKey: "code",
    header: "Code",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "username",
    header: "Nom / Prénom",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "processingLevel",
    header: "Niveau de traitement",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "places",
    header: "Lieux",
    cell: ({ row }) => row.original.places?.split("§").join(", "),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "comment",
    header: "Commentaire",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => (
      <span>
        {row.original.email}
        <br />
        {row.original.secoundEmail}
      </span>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
];

const columnsRedactors: ColumnDef<UserDto>[] = [
  {
    accessorKey: "code",
    header: "Code",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "activated",
    header: "Activé",
    cell: (info) =>
      info.getValue() ? (
        <IconCheck height={17} color="#333333" />
      ) : (
        <IconArchive height={17} color="#333333" />
      ),
    sortingFn: sortingFns.basic,
    enableColumnFilter: false,
  },
  {
    accessorKey: "id",
    header: "Nom / Prénom",
    cell: (row) => row.row.original.username,
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "phoneNumber",
    header: "Téléphone",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "comment",
    header: "Commentaire",
    cell: (info) => info.getValue(),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
  {
    accessorKey: "email",
    header: "Email",
    cell: ({ row }) => (
      <span>
        {row.original.email}
        <br />
        {row.original.secoundEmail}
      </span>
    ),
    sortingFn: sortingFns.alphanumeric,
    enableColumnFilter: false,
  },
];

const UsersPlanning = () => {
  const [users, setUsers] = React.useState<UserDto[]>([]);
  const [displayedUsers, setDisplayedUsers] = React.useState<UserDto[]>([]);
  const [loading, setLoading] = React.useState(true);
  const api = useApi();

  React.useEffect(() => {
    const fetchRecordings = async () => {
      const response = await getUsers(api);
      console.log(response);
      setUsers(response);
      setLoading(false);
    };
    fetchRecordings();
  }, [api]);

  React.useEffect(() => {
    if (window.location.pathname === "/clients") {
      setDisplayedUsers(users.filter((user) => user.role === "client"));
    } else {
      setDisplayedUsers(users.filter((user) => user.role === "membre"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, users]);

  return (
    <div>
      <HeaderAction planning />
      <Box p={30} bg="#F9FAFB">
        <Title mb={30}>
          {window.location.pathname === "/clients"
            ? "Données Clients"
            : "Données Rédacteurs"}
        </Title>
        <Box>
          <TableInit
            data={displayedUsers}
            setData={setUsers}
            loading={loading}
            columns={
              window.location.pathname === "/clients"
                ? columnsCustomers
                : columnsRedactors
            }
            redirect="user"
            // open={open}
            initialSorting={[{ id: "code", desc: false }]}
          />
        </Box>
      </Box>
    </div>
  );
};

export default UsersPlanning;
