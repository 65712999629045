import React, { useContext, useEffect, useState } from "react";
import { HeaderAction } from "../components/HeaderAction";
import { Button } from "@mantine/core";
import { IconUserPlus } from "../components/IconType";
import UsersTable from "../components/UsersTable";
import { UserDto, UserRole } from "../dto/user";
import { getUsers } from "../apiRoutes";
import { useApi } from "../useApi";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserContext";

const Users = () => {
  const [tableContent, setTableContent] = useState<UserDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const api = useApi();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const refresh = async () => {
    const usersList: UserDto[] = await getUsers(api);
    console.log("usersList", usersList);
    setTableContent(usersList);
    setTimeout(() => setLoading(false), 200);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <>
      <HeaderAction />
      <div style={{ padding: 32, backgroundColor: "#FCFCFD" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h1
              style={{
                margin: 0,
                marginTop: -10,
                fontSize: 40,
                fontWeight: 500,
              }}
            >
              Liste des utilisateurs
            </h1>
            <p
              style={{
                color: "#656565",
                fontWeight: 400,
                margin: 0,
                fontSize: 16,
              }}
            >
              Clients qui ont été enregistré dans cet espace.
            </p>
          </div>
          {user?.role === UserRole.administrateur && (
            <Button
              color="primary"
              leftIcon={IconUserPlus}
              onClick={() => navigate("/newuser")}
            >
              Nouvel utilisateur
            </Button>
          )}
        </div>
        <div
          style={{
            width: "100%",
            borderTop: "1px solid #EAECF0",
            marginTop: 20,
            marginBottom: 32,
          }}
        />
        <UsersTable
          refresh={refresh}
          loading={loading}
          tableContent={tableContent}
        />
      </div>
    </>
  );
};

export default Users;
